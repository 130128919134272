import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Steps } from 'antd'
import { toast } from 'react-toastify'

import { ArrowRight, BackArrowIcon, ElectrumBlue, MailIcon, PersonIcon, PhoneIcon } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
} from 'assets/images'

const MerdekaRegister = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
    ]
    cacheImages(imgs)
  }, [])

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const [showTnC, setShowTnC] = useState(false)
  const [acceptTNC, setAcceptTNC] = useState(false)

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    image_t1: '',
    image_t2: '',
    image_t3: '',
    title: '',
    profile_description: '',
    proof: '',
  })

  const [previews, setPreviews] = useState({
    image_t1: '',
    image_t2: '',
    image_t3: '',
    proof: '',
  })

  const [inputKey, setInputKey] = useState(Date.now())

  const handleChange = (e) => {
    const { name, value, type, files } = e.target
    if (type === 'file') {
      const file = files[0]
      if (file) {
        if (file.size > 10e6) {
          toast.warn(`Please upload a file smaller than 10 MB`)
          setFormData({
            ...formData,
            [name]: '',
          })
          setPreviews({
            ...previews,
            [name]: '',
          })
          setInputKey(Date.now())
        } else {
          setFormData({
            ...formData,
            [name]: file,
          })
          setPreviews({
            ...previews,
            [name]: URL.createObjectURL(file),
          })
        }
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const isNotFilled = formData.fullName === ''
  || formData.email === ''
  || formData.phoneNumber === ''
  || formData.title === ''
  || formData.profile_description === ''
  || formData.image_t1 === ''
  || formData.image_t2 === ''
  || formData.image_t3 === ''
  || formData.proof === ''

  const handleSubmit = () => {
    if (isNotFilled) {
      toast.warning('Semua kolom harus terisi!')
    } else {
      navigate('/merdeka-hias/preview', {
        state: { formData },
      })
    }
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#ffffff',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: '100',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* TITLE AND STEPS */}
        <div style={{ padding: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', textAlign: 'center' }}>
            Ikuti kompetisi dan dapatkan motor listrik Electrum H3!
          </div>
          <div style={{ paddingTop: '2rem', display: 'flex', justifyContent: 'center' }}>
            <Steps
              progressDot
              current={1}
              items={[
                { subTitle: 'Cara Mulai' },
                { subTitle: 'Kirim Karya' },
                { subTitle: 'Tinjau' },
              ]}
              responsive={false}
              style={{ width: '120%' }}
            />
          </div>
        </div>

        {/* DATA DIRI */}
        <div style={{ padding: '0 2rem', marginBottom: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.75rem' }}>
            Lengkapi Data Dirimu
          </div>
          {/* FULL NAME */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Nama Lengkap <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={PersonIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='text'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='fullName'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* PHONE NUMBER */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Nomor Whatsapp <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={PhoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='number'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='phoneNumber'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* EMAIL ADDRESS */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Alamat Email <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={MailIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='email'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='email'
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* KIRIM KARYA */}
        <div style={{ padding: '0 2rem', marginBottom: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.75rem' }}>
            Kirim Karyamu!
          </div>
          {/* TITLE */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Judul Karyamu <span style={{ color: 'red' }}>*</span>
            </div>
            <input
              type='text'
              style={{
                border: '1.75px solid #ECECEC',
                borderRadius: '7.5px',
                margin: '5px 0px',
                height: '33.5px',
                width: '-webkit-fill-available',
              }}
              name='title'
              onChange={handleChange}
            />
          </div>
          {/* DESCRIPTION */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Deskripsikan Karyamu <span style={{ color: 'red' }}>*</span>
            </div>
            <textarea
              rows={6}
              cols={50}
              style={{
                border: '1.75px solid #ECECEC',
                borderRadius: '7.5px',
                margin: '5px 0px',
                width: '-webkit-fill-available',
                resize: 'vertical',
                fontFamily: 'inherit',
              }}
              name='profile_description'
              placeholder='Deskripsikan karyamu mulai dari tema yang kamu pilih, alasan, referensi atau inspirasimu, dan arti dari karyamu.'
              onChange={handleChange}
            />
          </div>
        </div>

        {/* SUBMISSION */}
        <div style={{ padding: '0 2rem', marginBottom: '3rem' }}>
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.25rem' }}>
              Karya Full Image Template 1
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', marginBottom: '0.75rem' }}>
              Upload desain berupa full image karyamu, dengan format jpg atau jpeg.
              <br />
              <i style={{ fontFamily: 'Gilroy-Medium' }}>
                (Hanya JPG atau JPEG, Max 10 MB)
              </i>
            </div>
            {
              previews.image_t1
              &&
              <img
                src={previews.image_t1}
                alt="Preview 1"
                style={{
                  width: '100px',
                  height: '100px',
                  marginBottom: '0.75rem',
                  aspectRatio: 1,
                  borderRadius: '0.75rem',
                  objectFit: 'cover',
                  clipPath: 'inset(0 0 0 0)',
                }}
              />
            }
            <input type='file' accept='.jpg,.jpeg' key={inputKey} id='image_t1' name='image_t1' onChange={handleChange} style={{ display: 'none' }} />
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                fontFamily: 'Gilroy-Bold',
                color: 'white',
                fontSize: '0.875rem',
                backgroundColor: '#0B4571',
                padding: '0.75rem 1rem',
                border: 'none',
                borderRadius: '0.625rem',
              }}
              onClick={() => document.getElementById('image_t1').click()}
            >
              Upload File <ArrowRight />
            </button>
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.25rem' }}>
              Karya & Frame Kompetisi Template 2
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', marginBottom: '0.75rem' }}>
              Upload desain berupa karya beserta frame kompetisi, dengan format jpg atau jpeg.
              <br />
              <i style={{ fontFamily: 'Gilroy-Medium' }}>
                (Hanya JPG atau JPEG, Max 10 MB)
              </i>
            </div>
            {
              previews.image_t2
              &&
              <img
                src={previews.image_t2}
                alt="Preview 1"
                style={{
                  width: '100px',
                  height: '100px',
                  marginBottom: '0.75rem',
                  aspectRatio: 1,
                  borderRadius: '0.75rem',
                  objectFit: 'cover',
                  clipPath: 'inset(0 0 0 0)',
                }}
              />
            }
            <input type='file' accept='.jpg,.jpeg' key={inputKey} id='image_t2' name='image_t2' onChange={handleChange} style={{ display: 'none' }} />
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                fontFamily: 'Gilroy-Bold',
                color: 'white',
                fontSize: '0.875rem',
                backgroundColor: '#0B4571',
                padding: '0.75rem 1rem',
                border: 'none',
                borderRadius: '0.625rem',
              }}
              onClick={() => document.getElementById('image_t2').click()}
            >
              Upload File <ArrowRight />
            </button>
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.25rem' }}>
              Story Template 3
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', marginBottom: '0.75rem' }}>
              Upload desain berupa template story dari karyamu dengan format jpg atau jpeg, yang nantinya dapat kamu share di media sosial.
              <br />
              <i style={{ fontFamily: 'Gilroy-Medium' }}>
                (Hanya JPG atau JPEG, Max 10 MB)
              </i>
            </div>
            {
              previews.image_t3
              &&
              <img
                src={previews.image_t3}
                alt="Preview 1"
                style={{
                  width: '100px',
                  height: '100px',
                  marginBottom: '0.75rem',
                  aspectRatio: 1,
                  borderRadius: '0.75rem',
                  objectFit: 'cover',
                  clipPath: 'inset(0 0 0 0)',
                }}
              />
            }
            <input type='file' accept='.jpg,.jpeg' key={inputKey} id='image_t3' name='image_t3' onChange={handleChange} style={{ display: 'none' }} />
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                fontFamily: 'Gilroy-Bold',
                color: 'white',
                fontSize: '0.875rem',
                backgroundColor: '#0B4571',
                padding: '0.75rem 1rem',
                border: 'none',
                borderRadius: '0.625rem',
              }}
              onClick={() => document.getElementById('image_t3').click()}
            >
              Upload File <ArrowRight />
            </button>
          </div>
        </div>

        {/* PROOF */}
        <div style={{ padding: '0 2rem', marginBottom: '3rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.25rem' }}>
            Bukti Pengerjaan
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', marginBottom: '0.75rem' }}>
            Peserta <b>WAJIB</b> mengirimkan bukti pengerjaan berupa foto screenshot layar saat proses mendesain, dengan format jpg atau jpeg.
            <br />
            <i style={{ fontFamily: 'Gilroy-Medium' }}>
              (Hanya JPG atau JPEG, Max 10 MB)
            </i>
          </div>
          {
            previews.proof
            &&
            <img
              src={previews.proof}
              alt="Preview 1"
              style={{
                width: '100px',
                height: '100px',
                marginBottom: '0.75rem',
                aspectRatio: 1,
                borderRadius: '0.75rem',
                objectFit: 'cover',
                clipPath: 'inset(0 0 0 0)',
              }}
            />
          }
          <input type='file' accept='.jpg,.jpeg' key={inputKey} id='proof' name='proof' onChange={handleChange} style={{ display: 'none' }} />
          <button
            style={{
              cursor: 'pointer',
              display: 'flex',
              fontFamily: 'Gilroy-Bold',
              color: 'white',
              fontSize: '0.875rem',
              backgroundColor: '#0B4571',
              padding: '0.75rem 1rem',
              border: 'none',
              borderRadius: '0.625rem',
            }}
            onClick={() => document.getElementById('proof').click()}
          >
            Upload File <ArrowRight />
          </button>
        </div>

        {/* TNC */}
        <div style={{
          backgroundColor: 'white',
          padding: '0rem',
          margin: '2rem 2rem 1rem 2rem',
          borderRadius: '0.625rem',
        }}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setShowTnC(!showTnC)}
          >
            <div style={{ fontSize: '1.375rem', fontFamily: 'Gilroy-ExtraBold', textAlign: 'center' }}>
              Pastikan Membaca Syarat dan Ketentuan ya
            </div>
            <div style={{ transform: showTnC ? 'rotate(90deg) scale(0.75)' : 'rotate(270deg) scale(0.75)', maxWidth: 'min-content' }}>
              <BackArrowIcon width={20} height={20} />
            </div>
          </div>
          <div style={{ display: showTnC ? 'block' : 'none', marginTop: '0.75rem' }}>
            <div style={{ fontSize: '0.75rem', color: '#103856', userSelect: 'none' }}>
              <div style={{ fontFamily: 'Gilroy-SemiBold', marginBottom: '0.5rem' }}>
                Syarat dan ketentuan berikut ini (“<b>Syarat & Ketentuan</b>”) menguraikan peraturan dan ketentuan untuk berpartisipasi dalam kompetisi Merdeka Hias Electrum H3 (“<b>Kompetisi</b>”) yang diselenggarakan oleh PT Energi Kreasi Bersama (“<b>Electrum</b>”).
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', marginBottom: '0.5rem' }}>
                Dengan mengikuti Kompetisi ini, Anda dianggap telah menerima dan menyetujui Syarat & Ketentuan ini.
              </div>
              <div style={{ fontFamily: 'Gilroy-Bold' }}>
                A. Aturan Umum Kompetisi
              </div>
              <ol style={{ fontFamily: 'Gilroy-Semibold', paddingLeft: '1.5rem', marginBottom: '0.5rem' }}>
                {/* NO. 1 */}
                <li style={{ marginBottom: '0.5rem' }}>
                  Syarat Peserta Kompetisi (“<b>Peserta</b>”):
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Warga Negara Indonesia; dan
                    </li>
                    <li>
                      Berusia minimal 17 tahun.
                    </li>
                  </ol>
                </li>
                {/* NO. 2 */}
                <li style={{ marginBottom: '0.5rem' }}>
                  Syarat Pengajuan Design:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Design harus menggunakan template yang disediakan oleh Electrum pada {' '}
                      <a href='https://www.electrum.id/merdeka-hias/instruction' target='_blank'>
                        https://www.electrum.id/merdeka-hias/instruction
                      </a>{' '}
                      (“<b>Submission Page</b>”) dan sesuai dengan tema yang ditentukan oleh Electrum (“<b>Design</b>”).
                    </li>
                    <li>
                      Peserta harus melengkapi detail informasi yang tersedia pada Submission Page, termasuk mengunggah foto proses pengerjaan pembuatan Design.
                    </li>
                    <li>
                      Pengajuan Design hanya dapat dilakukan pada tanggal 15 Juli 2024 sampai tanggal 4 Agustus 2024.
                    </li>
                    <li>
                      Peserta hanya dapat melakukan pengajuan Design maksimal 1 kali. 
                    </li>
                    <li>
                      Pengajuan Design tidak dipungut biaya.
                    </li>
                  </ol>
                </li>
                {/* NO. 3 */}
                <li style={{ marginBottom: '0.5rem' }}>
                  Ketentuan Pemenang:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Akan terdapat 2 orang pemenang yaitu Juara Umum dan Juara Favorit.
                      <ol style={{ listStyleType: 'lower-roman' }}>
                        <li>
                          Juara Umum dipilih oleh dewan juri yang ditentukan oleh Electrum.
                        </li>
                        <li>
                          {/* Juara Favorit dipilih dari perolehan vote terbanyak dari voting terbuka yang diselenggarakan oleh Electrum pada {' '}
                          <a href='https://www.electrum.id/merdeka-hias/gallery' target='_blank'>
                            https://www.electrum.id/merdeka-hias/gallery
                          </a> */}
                          Juara Favorit dipilih dari perolehan vote terbanyak dari voting terbuka yang diselenggarakan oleh Electrum pada <u>https://www.electrum.id/merdeka-hias/gallery</u>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Pemenang akan diumumkan pada akun Instagram @electrum.id pada tanggal 17 Agustus 2024.
                    </li>
                    <li>
                      Keputusan pemilihan pemenang merupakan hak prerogratif Electrum dan merupakan keputusan yang mutlak dan tidak bisa diganggu gugat.
                    </li>
                  </ol>
                </li>
                {/* NO. 4 */}
                <li style={{ marginBottom: '0.5rem' }}>
                  Ketentuan Hadiah:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Masing-masing Juara Umum dan Juara Favorit akan mendapatkan 1 unit sepeda motor Electrum H3 dengan design sebagaimana diajukan oleh masing-masing pemenang untuk Kompetisi.
                    </li>
                    <li>
                      Dalam hal dinyatakan sebagai pemenang, anda bersedia hadir ke kantor Electrum untuk proses serah terima hadiah termasuk untuk memberikan data diri seperti KTP untuk keperluan pembuatan dokumen pendukung kepemilikan sepeda motor.
                    </li>
                  </ol>
                </li>
                {/* NO. 5 */}
                <li style={{ marginBottom: '0.5rem' }}>
                  Dengan berpartisipasi dalam Kompetisi ini, Peserta menyatakan dan menyetujui bahwa:
                  <ol style={{ listStyleType: 'lower-alpha' }}>
                    <li>
                      Design dan segala hak kekayaan intelektual atas Design adalah milik Electrum.  Peserta bersedia untuk bekerjasama dengan Electrum untuk keperluan pengalihan hak kekayaan intelektual atas Design tersebut, termasuk untuk datang ke kantor Electrum dan menandatangani segala dokumen untuk keperluan ini.
                    </li>
                    <li>
                      Electrum memiliki hak penuh untuk menggunakan Design beserta hak kekayaan intelektual terkait untuk keperluan usahanya. Electrum memiliki hak penuh untuk membuat, menampilkan dan menjual sepeda motor dengan Design yang diajukan oleh Peserta. Untuk menghindari keragu-raguan, Electrum tidak berkewajiban membayar royalti ataupun biaya apapun lainnya untuk dapat menggunakan Design baik secara komersial maupun tidak.
                    </li>
                    <li>
                      Design merupakan hasil karya otentik milik Peserta.
                    </li>
                    <li>
                      Data dan informasi pribadi yang dibagikan pada saat pengajuan Design dapat digunakan Electrum terkait dengan pengembangan layanan dan kegiatan usaha Electrum, termasuk untuk menghubungi anda pemberian informasi mengenai produk Electrum dan bentuk penawaran atau promosi lainnya. Ketentuan ini tetap berlaku setelah Kompetisi berakhir.
                    </li>
                  </ol>
                </li>
              </ol>
              <div style={{ fontFamily: 'Gilroy-Bold' }}>
                Electrum berhak untuk sewaktu-waktu mengubah Syarat & Ketentuan ini. 
              </div>
            </div>
          </div>
          <div style={{ borderBottom: '0.215rem solid #ECECEC', borderRadius: '100rem', marginTop: '0.35rem' }} />
        </div>

        {/* AGREE TNC */}
        <div style={{ marginBottom: '1.25rem', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <input type="checkbox" name="acceptTNC" value={acceptTNC} onClick={() => setAcceptTNC(!acceptTNC)} style={{ transform: 'scale(1.5)', marginRight: '1rem' }} />
          <label style={{ fontSize: '0.875rem', fontWeight: '500', color: '#888888', marginBottom: '0.25rem',fontFamily: 'Gilroy-Medium', textAlign: 'center' }}>
            Saya Telah Membaca dan Menyetujui <br/> <b>Syarat dan Ketentuan</b>
          </label>
        </div>

        {/* BUTTONS */}
        <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
          <button
            style={{
              cursor: acceptTNC ? 'pointer' : 'not-allowed',
              backgroundColor: acceptTNC ? '#0B4571' : '#EBEBEB',
              color: acceptTNC ? 'white' : '#A6A6A6',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: 'none',
              width: '100%',
              marginBottom: '0.5rem',
            }}
            onClick={() => acceptTNC && handleSubmit()}
          >
            Lanjut
          </button>
          <button
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              backgroundColor: 'white',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: 'none',
              width: '100%',
            }}
            onClick={() => navigate('/merdeka-hias/instruction')}
          >
            Kembali
          </button>
        </div>

      </div>
    </div>
  )
}

export default MerdekaRegister
