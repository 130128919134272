import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ArrowRight, ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
  KVMerdeka,
} from 'assets/images'
import Slider from 'components/slider'

Chart.register(CategoryScale)

const roundDecimalPlaces = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const HomeNewPromo = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const [showCountdown, setShowCountdown] = useState(true)

  const desiredDate = '2024-06-30' // Format: YYYY-MM-DD

  const calculateTimeLeft = () => {
    const difference = +new Date(desiredDate) - +new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (isObjectEmpty(timeLeft)) {
      setShowCountdown(false)
    } else {
      setShowCountdown(true)
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
      return () => clearTimeout(timer)
    }
  })

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time
  }

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
      KVMerdeka,
    ]
    cacheImages(imgs)
  }, [])

  /* WhatsApp Text Templace
  const textChat = encodeURIComponent(`Hi! I am interested in your electric motorcycles. [Electrum] (${window.location.href})`)
  */

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const [distance, setDistance] = useState(50)

  const EPCost = roundDecimalPlaces((distance * 90.9090909091), 0)

  const renderCostCalculator = () => {
    return (
      <div>
        <center style={{ margin: '1rem', padding: '1rem', backgroundColor: 'white' }}>
          <Slider
            label="Jarak Tempuh"
            minValue={0}
            maxValue={500}
            increment={1}
            initialValue={50}
            onValueChange={(e) => setDistance(e)}
          />
        </center>
        <div style={{ margin: '1rem' }}>
          <Bar
            datasetIdKey='id'
            options={{
              responsive: true,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
            data={{
              labels: ['Electrum H3', 'Motor 110 CC', 'Motor 135 CC'],
              datasets: [
                {
                  id: 1,
                  label: 'Fixed Cost',
                  data: [420000, 250000, 250000],
                  backgroundColor: ['#103856'],
                  barThickness: 60,
                },
                {
                  id: 2,
                  label: 'Running Cost',
                  data: [EPCost * 10, 17224 * 10, 17224 * 10],
                  backgroundColor: ['#F04C3E'],
                  barThickness: 60,
                },
                {
                  id: 3,
                  label: 'Maintenance Cost',
                  data: [200000, 350000, 350000],
                  backgroundColor: ['#D8E9E8'],
                  barThickness: 60,
                },
              ],
            }}
          />
        </div>
      </div>
    )
  }

  const url = new URL(window.location.href)
  const params = url.searchParams.get('utm_campaign')
  const decodedParams = decodeURI(params) === 'null' ? '' : decodeURI(params)

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#F0F8FE',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* SCROLL POSITION POPUP */}
        {showPopup && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            background: 'lightgrey',
            border: '1px solid black',
            color: 'red',
            fontWeight: 600,
            zIndex: -99999,
          }}>
            Scroll position: {scrollPosition.scrollTop} <br />
            {isScrolledToBottom && <div>You have scrolled to the bottom!</div>}
          </div>
        )}

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* TOP BANNER */}
        <div>
          <div style={{ paddingBottom: '1.25rem', backgroundColor: 'white' }}>
            <img
              effect='blur'
              alt='top-banner'
              src={KVMerdeka}
              loading='eager'
              onClick={() => navigate('/merdeka-hias')}
              style={{ width: '100%', cursor: 'pointer' }}
            />
          </div>
          <img effect='blur' alt='kv-merdeka' src={TopBannerJune} style={{ width: '100%' }} loading='eager' />
        </div>

        {/* COUNTDOWN */}
        {showCountdown && (
          <div style={{ padding: '2.5rem 2rem', backgroundColor: '#f4f7fa' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', marginBottom: '1rem', textAlign: 'center' }}>
              Promo Berakhir Dalam
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1.25rem' }}>
              {/* DAY */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.5rem' }}>
                <div style={{
                  backgroundColor: 'white',
                  padding: '0.75rem',
                  boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.25)',
                  borderRadius: '0.5rem',
                  fontWeight: '600',
                  fontSize: '1.45rem',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  {formatTime(timeLeft.days)}
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#848484' }}>
                  Hari
                </div>
              </div>
              {/* HOUR */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.5rem' }}>
                <div style={{
                  backgroundColor: 'white',
                  padding: '0.75rem',
                  boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.25)',
                  borderRadius: '0.5rem',
                  fontWeight: '600',
                  fontSize: '1.45rem',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  {formatTime(timeLeft.hours)}
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#848484' }}>
                  Jam
                </div>
              </div>
              {/* MINUTE */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.5rem' }}>
                <div style={{
                  backgroundColor: 'white',
                  padding: '0.75rem',
                  boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.25)',
                  borderRadius: '0.5rem',
                  fontWeight: '600',
                  fontSize: '1.45rem',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  {formatTime(timeLeft.minutes)}
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#848484' }}>
                  Menit
                </div>
              </div>
              {/* SECOND */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.5rem' }}>
                <div style={{
                  backgroundColor: 'white',
                  padding: '0.75rem',
                  boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.25)',
                  borderRadius: '0.5rem',
                  fontWeight: '600',
                  fontSize: '1.45rem',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  {formatTime(timeLeft.seconds)}
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#848484' }}>
                  Detik
                </div>
              </div>
            </div>
          </div>
        )}

        {/* FUTURE */}
        <img effect='blur' alt='future' src={Future} style={{ width: '100%' }} />

        {/* SEE PRODUCT */}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', marginBottom: '6rem' }}>
          <button
            style={{
              cursor: 'pointer',
              display: 'flex',
              fontFamily: 'Gilroy-Bold',
              color: 'white',
              fontSize: '1rem',
              backgroundColor: '#225E8B',
              padding: '0.75rem 1.5rem',
              border: 'none',
              borderRadius: '0.625rem',
            }}
            onClick={() => navigate('/product')}
          >
            Klik disini untuk lihat Detail Produk <ArrowRight />
          </button>
        </div>

        {/* FIXED SECTION */}
        <div style={{
          position: 'fixed',
          bottom: '0',
          backgroundColor: '#fff',
          borderRadius: '1.25rem 1.25rem 0 0',
          width: isMobile ? '100%' : '33%',
        }}>
          <div style={{ padding: '1em', display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <a
              href={`https://wa.me/6285190013535?text=${decodedParams}%20Halo%21%20Saya%20ingin%20info%20lebih%20lanjut%20mengenai%20Electrum%20H3.`}
              target='_blank'
              rel='noreferrer'
              style={{ height: '42.5px' }}
            >
              <img effect='blur' alt='chat-with-us' src={ChatWithUs} width={42.5} style={{ cursor: 'pointer' }} loading='eager' />
            </a>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                color: '#225E8B',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: '2px solid #225E8B',
                padding: '0.375rem 1rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => window.open(
                `https://wa.me/6285190013535?text=${decodedParams}%20Halo%21%20Saya%20mau%20buat%20jadwal%20booking%20test%20ride%20Electrum%20H3.`,
                '_blank',
                'rel=noopener noreferrer'
              )}
            >
              Daftar Test-ride
            </button>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: '#225E8B',
                borderRadius: '0.5rem',
                color: 'white',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: 'none',
                padding: '0.375rem 1rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => setChooseModal(true)}
            >
              Buy Now
            </button>
          </div>
        </div>

        {/* CHOOSE MODAL */}
        <Modal
          closeBtn={true}
          onClose={() => setChooseModal(false)}
          isOpen={chooseModal}
        >
          <div style={{ fontSize: '1.5rem', fontFamily: 'Gilroy-Bold', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.25rem' }}>
            Amankan Promonya Sekarang
          </div>
          <div style={{ fontSize: '0.85rem', fontFamily: 'Gilroy-SemiBold', color: '#848484', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.75rem' }}>
            Beli Electrum H3 melalui platform terpercaya kami.
          </div>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
              marginBottom: '1rem',
            }}
            onClick={() => {
              window.open(
                `https://wa.me/6285190013535?text=${decodedParams}%20Halo%21%20Saya%20mau%20promo%20Electrum%20H3.`,
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Whatsapp Official Electrum
          </button>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
            }}
            onClick={() => {
              window.open(
                'https://tokopedia.link/electrumindonesia',
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Tokopedia
          </button>
        </Modal>

      </div>
    </div>
  )
}

export default HomeNewPromo
