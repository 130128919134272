import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faXmark } from '@fortawesome/free-solid-svg-icons'
import './modal.css'

const Modal = ({ isOpen, closeBtn, onClose, children }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && typeof onClose === 'function') {
        onClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  if (!isOpen) return null

  return (
    <div className="modal-overlay">
      <div className="modal">
        {closeBtn && (
          <button
            className="modal-close"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faXmark} color='#F04C3E' fontSize='1.5rem' />
          </button>
        )}
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
