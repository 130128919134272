import axios from "axios"
import { ELECTRUM_API_URL } from "../consts"

export const getAllDesigns = async (sortBy, name, page, limit) => {
  const result = await axios.get(`${ELECTRUM_API_URL}/design?sortBy=${sortBy}&name=${name ? name : ''}&page=${page}&limit=${limit}`)
  return result.data
}

export const getDesignById = async (id) => {
  const result = await axios.get(`${ELECTRUM_API_URL}/design/${id}`)
  return result.data
}
