import { useQuery } from 'react-query'
import { getAllDesigns, getDesignById } from '../api'

export const useGetAllDesigns = (sortBy, name, page, limit) => {
  return useQuery(
    ['all-designs', sortBy, name, page, limit],
    () => getAllDesigns(sortBy, name, page, limit),
    { keepPreviousData: true },
  )
}

export const useGetDesignById = (id) => {
  return useQuery(
    ['design-by-id', id],
    () => getDesignById(id),
    { keepPreviousData: true },
  )
}
