export const convertImageSrcToBlob = async (imageSrc) => {
  try {
    const response = await fetch(imageSrc)
    const blob = await response.blob()
    return blob
  } catch (error) {
    console.error('Error converting image URL to blob:', error)
    throw error
  }
}
