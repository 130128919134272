import React, { useEffect, useState } from 'react';
import { Slider as AntdSlider } from 'antd'

const Slider = ({
  label,
  minValue,
  maxValue,
  increment,
  initialValue,
  onValueChange,
  style,
}) => {
  const [value, setValue] = useState(initialValue || minValue);

  useEffect(() => {
    setValue(initialValue || minValue);
  }, [initialValue, minValue]);

  const handleChange = (value) => {
    setValue(value)
    onValueChange(value)
  };

  return (
    <div
      className="custom-range-slider"
      style={style}
    >
      <label
        htmlFor="myRange"
        style={{
          maxWidth: "25rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily: 'Gilroy-Medium',
        }}
      >
        <div className="sliderLabelTopLeft">{label}</div>
        <div className="sliderLabelTopRight">
          {value} km
        </div>
      </label>
      <AntdSlider
        min={minValue}
        max={maxValue}
        step={increment}
        defaultValue={initialValue}
        value={value}
        onChange={handleChange}
        style={{ margin: '0.85rem 0' }}
        tooltip={{ formatter: null }}
      />
      <label
        style={{
          maxWidth: "25rem",
          display: "flex",
          justifyContent: "space-between",
          color: "#999",
          fontFamily: 'Gilroy-SemiBold',
        }}
      >
        <div className="sliderLabelBottom">{minValue} km</div>
        <div className="sliderLabelBottom">{maxValue} km</div>
      </label>
    </div>
  );
};

export default Slider;
