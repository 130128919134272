import React, { useEffect } from 'react'

const modal = {
  position: 'fixed',
  zIndex: 10000000,
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.45)',
  backdropFilter: 'blur(5px)',
}

const close = {
  position: 'absolute',
  top: 15,
  right: 35,
  color: '#f1f1f1',
  fontSize: 40,
  fontWeight: 'bold',
  cursor: 'pointer',
}

const modalContent = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  margin: 'auto',
}

export const Modal = ({ onOpen, children }) => {
  return <div onClick={onOpen}>{children}</div>
}

export const ModalContent = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    if (isOpen) {
      window.addEventListener('keydown', handleEsc)
    }
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return (
    <div style={modal}>
      <span style={close} onClick={onClose}>
        &times;
      </span>
      <div style={modalContent}>{children}</div>
    </div>
  )
}
