export const C3 = 23157000 // Scoopy price OTR
export const C4 = 24100000 // Vario Price OTR
export const C5 = 23900000 // H3 Price OTR - Beli Batre
export const C6 = 19900000 // H3 Price OTR - Sewa Batre
export const C7 = 48 // KM per liter Beat
export const C8 = 45 // KM per liter Vario
export const C9 = 41 // KM per KWh
export const C10 = 10000 // IDR per liter Pertalite
export const C11 = 13000 // IDR per liter Pertamax
export const C12 = 1442 // IDR per KWH charge - Electricity
export const C13 = 8500000 // Batt cost per unit
export const C14 = 1300 // Cycles per battery
export const C15 = 1.8 // KWh per battery
export const C16 = 0 // IDR per KWH battery usage fee
export const C17 = 3500 // IDR per KWH swap
export const C18 = 230000 // Subs fee battery per month
export const C19 = 150000 // Maintenance cost Vario per month>80km
export const C20 = 100000 // Maintenance cost Beat per month>80km
export const C21 = 40000 // Maintenance cost H3 per month>80km
export const C22 = 100000 // Maintenance cost Vario per month<80km
export const C23 = 60000 // Maintenance cost Beat per month<80km
export const C24 = 30000 // Maintenance cost H3 per month<80km
export const C25 = 50000 // Maintenance cost Vario per month<40km
export const C26 = 30000 // Maintenance cost Beat per month<40km
export const C27 = 20000 // Maintenance cost H3 per month<40km
export const C28 = 60 // Depre