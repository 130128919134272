import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import template_by_electrum from 'assets/MerdekaHias_Template.fig'

import { ArrowRight, BackArrowIcon, ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
} from 'assets/images'
import { Steps } from 'antd'

const MerdekaInstruction = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
    ]
    cacheImages(imgs)
  }, [])

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const stepList = [
    {
      title: 'Daftar & Join Grup',
      description: (
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#0B4571', marginBottom: '0.75rem' }}>
          Daftarkan dirimu dengan join grup Whatsapp {' '}
          <a href='https://chat.whatsapp.com/CPUWqy67OCL370IFT2UkgL' target='_blank'>
            di sini
          </a>
        </div>
      ),
      download: false,
    },
    {
      title: 'Pilih Tema',
      description: (
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#0B4571', marginBottom: '0.75rem' }}>
          Ada 2 tema yang dapat kamu pilih, yaitu <b>“Indahnya Masa Depan Transportasi Elektrik di Indonesia”</b> dan <b>“Pahlawan Energi untuk Negeri”</b>
        </div>
      ),
      download: false,
    },
    {
      title: 'Download Template Desain',
      description: (
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#0B4571', marginBottom: '0.75rem' }}>
          Download file template Figma yang sudah Electrum sediakan dan mulai berkreasi mendesain body motor Electrum H3. Desainmu harus sesuai dengan template ya.
        </div>
      ),
      download: true,
    },
    {
      title: 'Upload Karyamu',
      description: (
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#0B4571', marginBottom: '0.75rem' }}>
          Upload karyamu sebelum 4 Agustus 2024
        </div>
      ),
      download: false,
    },
    {
      title: 'Share Ke Media Sosialmu',
      description: (
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#0B4571', marginBottom: '0.75rem' }}>
          Share karyamu ke Instagram. Pastikan akunmu tidak di-private dan berubah username-nya selama kompetisi berlangsung. Jangan lupa tag @electrum.id dan hashtag #MerdekaHiasElectrumH3 pada postingan-mu.
        </div>
      ),
      download: false,
    },
  ]

  const renderSteps = () => {
    return stepList.map((item, idx) => {
      return (
        <div
          key={idx}
          style={{ marginBottom: (idx === stepList.length - 1) ? '0rem' : '2rem' }}
        >
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem', marginBottom: '0.5rem' }}>
            {idx + 1}. {item.title}
          </div>
          {item.description}
          {
            item.download
            &&
            <a href={template_by_electrum} download>
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  fontFamily: 'Gilroy-Bold',
                  color: 'white',
                  fontSize: '0.875rem',
                  backgroundColor: '#0B4571',
                  padding: '0.75rem 1rem',
                  border: 'none',
                  borderRadius: '0.625rem',
                }}
              >
                Download File <ArrowRight />
              </button>
            </a>
          }
        </div>
      )
    })
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#ffffff',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: '100',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* TITLE AND STEPS */}
        <div style={{ padding: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', textAlign: 'center' }}>
            Ikuti kompetisi dan dapatkan motor listrik Electrum H3!
          </div>
          <div style={{ paddingTop: '2rem', display: 'flex', justifyContent: 'center' }}>
            <Steps
              progressDot
              current={0}
              items={[
                { subTitle: 'Cara Mulai' },
                { subTitle: 'Kirim Karya' },
                { subTitle: 'Tinjau' },
              ]}
              responsive={false}
              style={{ width: '120%' }}
            />
          </div>
        </div>

        {/* STEPS */}
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.75rem' }}>
            Gini Caranya Ikutan!
          </div>
          {renderSteps()}
          <ul style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', marginLeft: '-1.75rem', marginTop: '2.5rem' }}>
            <li>Akan ada 2 pemenang yang berhak mendapatkan total 2 unit motor listrik Electrum H3 sesuai dengan hasil desain, yaitu juara umum dan juara favorit.</li>
            <li>Juara umum dipilih oleh dewan juri yang ditentukan oleh Electrum.</li>
            <li>Juara favorit dipilih dari perolehan vote terbanyak dari voting terbuka yang diselenggarakan pada 5-11 Agustus 2024.</li>
            <li>Pemenang akan diumumkan pada akun Instagram @electrum.id pada tanggal 17 Agustus 2024.</li>
            <li>Punya pertanyaan lebih lanjut? DM kami melalui Instagram @electrum.id.</li>
          </ul>
        </div>

        {/* BUTTONS */}
        <div style={{ padding: '1rem 2rem 2rem 2rem', marginTop: '1rem' }}>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: '#0B4571',
              color: 'white',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: 'none',
              width: '100%',
              marginBottom: '0.5rem',
            }}
            onClick={() => navigate('/merdeka-hias/register')}
          >
            Mulai Kirim Karya
          </button>
          <button
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              backgroundColor: 'white',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: 'none',
              width: '100%',
            }}
            onClick={() => navigate('/merdeka-hias')}
          >
            Kembali
          </button>
        </div>

      </div>
    </div>
  )
}

export default MerdekaInstruction
