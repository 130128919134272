import React from "react";

function BackArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect width="30" height="30" fill="#225E8B" rx="15"></rect>
      <path
        fill="#fff"
        d="M17.604 22.583l-7.02-7a.728.728 0 01-.177-.27.916.916 0 01-.053-.313c0-.111.018-.215.053-.313a.727.727 0 01.176-.27l7.021-7.021a.99.99 0 01.73-.292c.291 0 .541.104.75.313a1 1 0 01.312.729 1 1 0 01-.313.729L12.958 15l6.125 6.125a.976.976 0 01.292.718c0 .285-.104.532-.312.74a1 1 0 01-.73.313 1 1 0 01-.729-.313z"
      ></path>
    </svg>
  );
}

export default BackArrowIcon;