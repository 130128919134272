import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import Slider from 'components/slider'

import {
  C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13, C14, C15, C16, C17, C18, C19, C20, C21, C22, C23, C24, C25, C26, C27
} from 'data/cost-values'

import { ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
} from 'assets/images'
import FileSaver from 'file-saver'
import CostCalculator from 'components/cost-calculator'

Chart.register(CategoryScale)

const Home = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
    ]
    cacheImages(imgs)
  }, [])

  /* WhatsApp Text Templace
  const textChat = encodeURIComponent(`Hi! I am interested in your electric motorcycles. [Electrum] (${window.location.href})`)
  */

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const [files, setFiles] = useState([])

  const fileSelectedHandler = (e) => {
    setFiles([...files, ...e.target.files])
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#F0F8FE',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* SCROLL POSITION POPUP */}
        {showPopup && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            background: 'lightgrey',
            border: '1px solid black',
            color: 'red',
            fontWeight: 600,
            zIndex: -99999,
          }}>
            Scroll position: {scrollPosition.scrollTop} <br />
            {isScrolledToBottom && <div>You have scrolled to the bottom!</div>}
          </div>
        )}

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* COST CALCULATOR */}
        <CostCalculator />

        {/* FIXED SECTION */}
        <div style={{
          position: 'fixed',
          bottom: '0',
          backgroundColor: '#fff',
          borderRadius: '1.25rem 1.25rem 0 0',
          width: isMobile ? '100%' : '33%',
        }}>
          <div style={{ padding: '1em', display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <a
              href='https://wa.me/6285190013535?text=Halo%21%20Saya%20ingin%20info%20lebih%20lanjut%20mengenai%20Electrum%20H3.'
              target='_blank'
              rel='noreferrer'
              style={{ height: '42.5px' }}
            >
              <img effect='blur' alt='chat-with-us' src={ChatWithUs} width={42.5} style={{ cursor: 'pointer' }} loading='eager' />
            </a>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                color: '#225E8B',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: '2px solid #225E8B',
                padding: '0.375rem 1rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => window.open(
                'https://wa.me/6285190013535?text=Halo%21%20Saya%20mau%20buat%20jadwal%20booking%20test%20ride%20Electrum%20H3.',
                '_blank',
                'rel=noopener noreferrer'
              )}
            >
              Daftar Test-ride
            </button>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: '#225E8B',
                borderRadius: '0.5rem',
                color: 'white',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: 'none',
                padding: '0.375rem 1rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => setChooseModal(true)}
            >
              Buy Now
            </button>
          </div>
        </div>

        {/* CHOOSE MODAL */}
        <Modal
          closeBtn={true}
          onClose={() => setChooseModal(false)}
          isOpen={chooseModal}
        >
          <div style={{ fontSize: '1.5rem', fontFamily: 'Gilroy-Bold', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.25rem' }}>
            Amankan Promonya Sekarang
          </div>
          <div style={{ fontSize: '0.85rem', fontFamily: 'Gilroy-SemiBold', color: '#848484', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.75rem' }}>
            Beli Electrum H3 melalui platform terpercaya kami.
          </div>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
              marginBottom: '1rem',
            }}
            onClick={() => {
              window.open(
                'https://wa.me/6285190013535?text=Halo%21%20Saya%20mau%20promo%20Electrum%20H3..',
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Whatsapp Official Electrum
          </button>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
            }}
            onClick={() => {
              window.open(
                'https://tokopedia.link/electrumindonesia',
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Tokopedia
          </button>
        </Modal>

      </div>
    </div>
  )
}

export default Home
