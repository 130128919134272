import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from 'react-query'
import { postRegisterUserVoters } from 'apis/api'
import { toast } from 'react-toastify'
import { Modal as ModalImage, ModalContent as ModalImageContent } from 'components/modal-image'

import { ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
} from 'assets/images'
import { Spin, Steps } from 'antd'
import useRegisterUserVoters from 'apis/api/user-voters'

const MerdekaPreview = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
    ]
    cacheImages(imgs)
  }, [])

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const location = useLocation()
  const { formData } = location.state

  // const { mutate, isLoading } = useMutation({
  //   mutationFn: (payload) => postRegisterUserVoters(payload),
  //   onSuccess: (response) => {
  //     if (response.code === 201) {
  //       toast.success('Submission Submitted Successfully!')
  //       navigate('/merdeka-hias/gallery')
  //     } else if (response.code === 400) {
  //       toast.warn('Email and/or phone number already exists')
  //       navigate('/merdeka-hias/register')
  //     } else if (response.code === 500) {
  //       toast.warn('An error occurred while registering user')
  //       navigate('/merdeka-hias/register')
  //     }
  //   },
  //   onError: (err) => {
  //     const errMessage = err.response.data.message
  //     toast.error(errMessage)
  //   },
  // })

  const { mutate, isLoading, uploadProgress } = useRegisterUserVoters()

  const handleSubmit = (e) => {
    e.preventDefault()
    const createFormData = (data) => {
      const formDataObj = new FormData()
      for (const key in data) {
        if (data[key] instanceof File) {
          formDataObj.append(key, data[key])
        } else {
          formDataObj.append(key, data[key])
        }
      }
      return formDataObj
    }
    const formDataToSend = createFormData(formData)
    mutate(formDataToSend)
  }

  if (!formData || formData === undefined || formData === null) {
    navigate('/merdeka-hias/register')
  }

  const [showSub1, setShowSub1] = useState(false)
  const [showSub2, setShowSub2] = useState(false)
  const [showSub3, setShowSub3] = useState(false)

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#ffffff',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: '100',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* TITLE AND STEPS */}
        <div style={{ padding: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', textAlign: 'center' }}>
            Ikuti kompetisi dan dapatkan motor listrik Electrum H3!
          </div>
          <div style={{ paddingTop: '2rem', display: 'flex', justifyContent: 'center' }}>
            <Steps
              progressDot
              current={2}
              items={[
                { subTitle: 'Cara Mulai' },
                { subTitle: 'Kirim Karya' },
                { subTitle: 'Tinjau' },
              ]}
              responsive={false}
              style={{ width: '120%' }}
            />
          </div>
        </div>

        {/* CONTENT PREVIEW */}
        <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '1.5rem' }}>
            Cek lagi, pastikan ini karya terbaikmu!
          </div>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '1rem' }}>
            {formData.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem', color: '#103856', marginBottom: '0.5rem' }}>
            {formData.profile_description}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#0B4571', fontStyle: 'italic', marginBottom: '2rem' }}>
            Karya dari <b>{formData.fullName}</b>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
            <div>
              <ModalImage onOpen={() => setShowSub1(true)}>
                <img
                  alt={`submission1`}
                  src={URL.createObjectURL(formData.image_t1)}
                  loading='eager'
                  style={{ cursor: 'pointer', width: '100%', height: 'auto', aspectRatio: 1, borderRadius: '0.75rem', objectFit: 'cover', clipPath: 'inset(0 0 0 0)' }}
                />
              </ModalImage>
              {showSub1 && (
                <ModalImageContent isOpen={showSub1} onClose={() => setShowSub1(false)}>
                  <img
                    alt={`submission1`}
                    src={URL.createObjectURL(formData.image_t1)}
                    loading='eager'
                    style={{ width: '100%', height: 'auto', maxWidth: '100vw', maxHeight: '100vh', objectFit: 'contain' }}
                  />
                </ModalImageContent>
              )}
            </div>
            <div>
              <ModalImage onOpen={() => setShowSub2(true)}>
                <img
                  alt={`submission2`}
                  src={URL.createObjectURL(formData.image_t2)}
                  loading='eager'
                  style={{ cursor: 'pointer', width: '100%', height: 'auto', aspectRatio: 1, borderRadius: '0.75rem', objectFit: 'cover', clipPath: 'inset(0 0 0 0)' }}
                />
              </ModalImage>
              {showSub2 && (
                <ModalImageContent isOpen={showSub2} onClose={() => setShowSub2(false)}>
                  <img
                    alt={`submission2`}
                    src={URL.createObjectURL(formData.image_t2)}
                    loading='eager'
                    style={{ width: '100%', height: 'auto', maxWidth: '100vw', maxHeight: '100vh', objectFit: 'contain' }}
                  />
                </ModalImageContent>
              )}
            </div>
            <div>
              <ModalImage onOpen={() => setShowSub3(true)}>
                <img
                  alt={`submission3`}
                  src={URL.createObjectURL(formData.image_t3)}
                  loading='eager'
                  style={{ cursor: 'pointer', width: '100%', height: 'auto', aspectRatio: 1, borderRadius: '0.75rem', objectFit: 'cover', clipPath: 'inset(0 0 0 0)' }}
                />
              </ModalImage>
              {showSub3 && (
                <ModalImageContent isOpen={showSub3} onClose={() => setShowSub3(false)}>
                  <img
                    alt={`submission3`}
                    src={URL.createObjectURL(formData.image_t3)}
                    loading='eager'
                    style={{ width: '100%', height: 'auto', maxWidth: '100vw', maxHeight: '100vh', objectFit: 'contain' }}
                  />
                </ModalImageContent>
              )}
            </div>
          </div>
        </div>

        {/* BUTTONS */}
        <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: '#0B4571',
              color: 'white',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: 'none',
              width: '100%',
              marginBottom: '0.5rem',
            }}
            onClick={() => setOpenConfirmModal(true)}
          >
            Kirim Karya
          </button>
          <button
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              backgroundColor: 'white',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: '1.5px solid #0B4571',
              width: '100%',
            }}
            onClick={() => navigate('/merdeka-hias/register')}
          >
            Kembali
          </button>
        </div>

        {/* CONFIRM MODAL */}
        <Modal
          closeBtn={!isLoading ? true : false}
          onClose={() => setOpenConfirmModal(false)}
          isOpen={openConfirmModal}
        >
          <div style={{ fontSize: '1.5rem', fontFamily: 'Gilroy-Bold', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '2.25rem' }}>
            Semua Sudah Sesuai?
          </div>
          <div style={{ fontSize: '0.85rem', fontFamily: 'Gilroy-SemiBold', color: '#848484', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '2.75rem' }}>
            Kamu hanya dapat mengirim satu kali. Apakah semua sudah sesuai?
          </div>
          <button
            style={{
              cursor: !isLoading ? 'pointer' : 'not-allowed',
              backgroundColor: !isLoading ? '#0B4571' : '#EBEBEB',
              color: !isLoading ? 'white' : '#A6A6A6',
              borderRadius: '0.5rem',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: !isLoading ? '1.5px solid #0B4571' : '1.5px solid #B8B8B8',
              padding: '0.65rem 0.75rem',
              width: '100%',
              marginBottom: '0.675rem',
            }}
            onClick={handleSubmit}
          >
            { !isLoading ? 'Ya, Kirim Karya' : <Spin /> }
          </button>
          {isLoading && (
            <div style={{ marginBottom: '0.5rem' }}>
              <div style={{ fontSize: '0.85rem', fontFamily: 'Gilroy-SemiBold' }}>
                Upload progress: {Math.round(uploadProgress)}%
              </div>
              <progress
                value={uploadProgress}
                max="100"
                style={{ width: '100%' }}
              />
            </div>
          )}
          <button
            style={{
              cursor: !isLoading ? 'pointer' : 'not-allowed',
              backgroundColor: 'white',
              color: !isLoading ? '#0B4571' : '#B8B8B8',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              borderRadius: '0.5rem',
              border: !isLoading ? '1.5px solid #0B4571' : '1.5px solid #B8B8B8',
              padding: '0.65rem 0.75rem',
              width: '100%',
            }}
            onClick={() => !isLoading && setOpenConfirmModal(false)}
          >
            Kembali
          </button>
        </Modal>

      </div>
    </div>
  )
}

export default MerdekaPreview
