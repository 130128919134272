import React from "react"

function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 26 26"
    >
      <rect
        width="2.987"
        height="17.92"
        fill="currentColor"
        rx="1.493"
        transform="rotate(-135 11.803 10.055)"
      ></rect>
      <rect
        width="2.987"
        height="17.92"
        fill="currentColor"
        rx="1.493"
        transform="rotate(-44.999 8.216 -12.05)"
      ></rect>
    </svg>
  )
}

export default ArrowRight