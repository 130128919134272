import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal, StarRating } from 'common'
import Webcam from 'react-webcam'
import { Button, Collapse, DatePicker, Form, Input, Progress, Upload, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { ArrowRightOutline, ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerProduct,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
  KVProklamasi1,
  KVMerdeka,
  KVProklamasi2,
  BatterySwap2,
  H3199,
  H3239,
  TNCSeparator,
  SepekanSquare,
} from 'assets/images'
import { CameraOutlined, UploadOutlined } from '@ant-design/icons'
import usePostRentSepekan from 'apis/api/sepekan'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'

const videoConstraints = {
  audio: false,
  noiseSuppression: false,
  width: 320,
  height: 280,
  facingMode: "user",
  mirrored: true,
}

const audioConstraints = {
  suppressLocalAudioPlayback: true,
  noiseSuppression: true,
  echoCancellation: true,
}

const { TextArea } = Input

const SepekanForm = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerProduct,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
      KVProklamasi1,
      KVProklamasi2,
    ]
    cacheImages(imgs)
  }, [])

  /* 
    WhatsApp Text Templace
    const textChat = encodeURIComponent(`Hi! I am interested in your electric motorcycles. [Electrum] (${window.location.href})`)
  */

  // GUIDELINES
  const guidelinesList = [
    'Kamu bisa coba pakai selama 1 minggu untuk rasakan performa dan ekosistem Electrum.',
    'Sebagai ilustrasi, jika pemakaian dimulai hari Senin, maka pengembalian di hari Senin minggu depannya.',
    'Pemakaian termasuk 1 unit motor listrik Electrum H3 dan 1 buah baterai.',
    'Biaya pemakaian sebesar Rp 250.000,- yang dibayarkan di awal pemesanan.',
    'Pengambilan unit dilakukan di showroom Electrum Pondok Indah, Jakarta. Tersedia opsi home delivery sebesar Rp 150.000,- per pengantaran.',
    'Metode pengisian energi melalui swap baterai di BSS Electrum, menggunakan Electrum Pulsa yang di-top up secara mandiri.',
    'Kamu tinggal mengisi formulir di bawah ini untuk mulai melakukan pemakaian. Yuk, pakai dan rasakan sekarang!',
  ]
  const renderGuidelines = () => {
    return guidelinesList.map((item, index) => (
      <li style={{ fontFamily: 'Gilroy-Semibold', fontSize: '0.75rem', color: '#71869C' }}>
        {item}
      </li>
    ))
  }

  // PICKUP OPTION
  const [pickupOption, setPickupOption] = useState('')

  // FORM STATE
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    email_work: '',
    img_nik: '',
    img_simc: '',
    img_selfie: '',
    rent_date: '',
    pickup_point: pickupOption,
    address: '',
  })

  // WEBCAM
  const [urlSelfie, setUrlSelfie] = useState(null)
  const [showWebcam, setShowWebcam] = useState(false)
  const webcamRef = useRef(null)
  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot()
    setUrlSelfie(imageSrc)
    setFormData((prevState) => ({
      ...prevState,
      img_selfie: urlSelfie,
    }))
  }, [webcamRef])

  // FORM ACTIONS
  const handleChangeForm = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      img_selfie: urlSelfie,
      pickup_point: pickupOption,
      [name]: value,
    })
  }
  const handleChangeFormImage = (name) => (info) => {
    const { file } = info
    const fileObj = file.originFileObj || file
    if (fileObj) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: fileObj,
      }))
    }
  }
  const handlePickupOptionChange = (option) => {
    setPickupOption(option)
    setFormData((prevData) => ({
      ...prevData,
      pickup_point: option,
    }))
  }

  // SUBMIT ACTION
  const { mutate, isLoading, uploadProgress } = usePostRentSepekan()
  const isNotFilled =
    formData.fullName === ''
    || formData.email === ''
    || formData.email_work === ''
    || formData.phoneNumber === ''
    || formData.img_nik === ''
    || formData.img_simc === ''
    || formData.img_selfie === null
    || formData.rent_date === ''
    || formData.pickup_point === ''
  const handleSubmitForm = () => {
    if (isNotFilled) {
      toast.warn('Semua kolom harus terisi!')
    } else {
      const formDataToSend = new FormData()
      // Append text fields
      formDataToSend.append('fullName', formData.fullName)
      formDataToSend.append('phoneNumber', formData.phoneNumber)
      formDataToSend.append('email', formData.email)
      formDataToSend.append('email_work', formData.email_work)
      formDataToSend.append('rent_date', formData.rent_date)
      formDataToSend.append('pickup_point', formData.pickup_point)
      formDataToSend.append('address', formData.address)
      // Append files
      if (formData.img_nik) {
        formDataToSend.append('img_nik', formData.img_nik)
      }
      if (formData.img_simc) {
        formDataToSend.append('img_simc', formData.img_simc)
      }
      if (formData.img_selfie) {
        formDataToSend.append('img_selfie', formData.img_selfie)
      }
      mutate(formDataToSend)
    }
    // console.log('formData', formData)
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#F0F8FE',
        }}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: 9999,
        }}>
          <div style={{
            display: 'flex',
            // justifyContent: 'space-between',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer', visibility: 'hidden' }}
              // onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                // onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* KV */}
        <img effect='blur' alt='sepekan-square' src={SepekanSquare} style={{ width: '100%' }} loading='eager' />

        {/* GUIDELINES */}
        <div style={{ padding: '2rem' }}>
          <div style={{ backgroundColor: 'white', padding: '1.25rem', borderRadius: '0.625rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.25rem', color: '#103856', textAlign: 'center' }}>
              Yuk, Ikutan SEPEKAN Electrum!
            </div>
            <div style={{ marginTop: '1rem' }}>
              <img alt='tnc-separator' src={TNCSeparator} style={{ width: '100%', marginBottom: '0.25rem' }} />
              <ul style={{ marginLeft: '-1.5rem', marginBottom: '0rem' }}>
                {renderGuidelines()}
              </ul>
            </div>
          </div>
        </div>

        {/* FORM */}
        <div style={{ padding: '1rem 2rem 6.5rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', marginBottom: '0.75rem' }}>
            Isi Formulir
          </div>
          <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '0.625rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', color: '#103856', marginBottom: '1.5rem' }}>
              Formulir Sepekan Electrum
            </div>
            {/* FULL NAME */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nama Lengkap
              </div>
              <Input name='fullName' onChange={handleChangeForm} />
            </div>
            {/* PHONE NUMBER */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nomor Telefon
              </div>
              <Input name='phoneNumber' type='number' onChange={handleChangeForm} />
            </div>
            {/* EMAIL PRIVATE */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                E-mail Pribadi
              </div>
              <Input name='email' onChange={handleChangeForm} />
            </div>
            {/* EMAIL WORK */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                E-mail Kantor
              </div>
              <Input name='email_work' onChange={handleChangeForm} />
            </div>
            {/* NIK */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Foto NIK
              </div>
              <div style={{ display: 'flex', marginTop: '0.25rem', width: '100%' }}>
                <Form.Item
                  name='img_nik'
                  style={{ width: '100%', marginBottom: '0rem' }}
                >
                  <Upload
                    type='file'
                    maxCount={1}
                    onChange={handleChangeFormImage('img_nik')}
                    accept='image/png, image/jpg, image/jpeg'
                    beforeUpload={() => false}
                    style={{ width: '100%' }}
                  >
                    <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
                      Upload Foto
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
            {/* SIM C */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Foto SIM C
              </div>
              <div style={{ display: 'flex', marginTop: '0.25rem', width: '100%' }}>
                <Form.Item
                  name='img_simc'
                  style={{ width: '100%', marginBottom: '0rem' }}
                >
                  <Upload
                    type='file'
                    maxCount={1}
                    onChange={handleChangeFormImage('img_simc')}
                    accept='image/png, image/jpg, image/jpeg'
                    beforeUpload={() => false}
                    style={{ width: '100%' }}
                  >
                    <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
                      Upload Foto
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
            {/* SELFIE */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Foto Selfie
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  icon={<CameraOutlined />}
                  style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: showWebcam ? '0.75rem' : '0rem' }}
                  onClick={() => setShowWebcam(!showWebcam)}
                >
                  Ambil Foto
                </Button>
                <div style={{ display: showWebcam ? 'flex' : 'none' }}>
                  {urlSelfie ? (
                    <img src={urlSelfie} alt="Screenshot" style={{ borderRadius: '0.5rem' }} />
                  ) : (
                    <Webcam
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                      audioConstraints={audioConstraints}
                      style={{ borderRadius: '0.5rem' }}
                    />
                  )}
                </div>
                <div style={{ display: showWebcam ? 'flex' : 'none', columnGap: '1rem', marginTop: '1rem' }}>
                  {urlSelfie ? (
                    <Button
                      type='primary'
                      style={{ width: '100%' }}
                      onClick={() => setUrlSelfie(null)}
                    >
                      Re-take
                    </Button>
                  ) : (
                    <Button
                      type='primary'
                      name='img_selfie'
                      style={{ width: '100%' }}
                      onClick={() => {
                        capturePhoto()
                        handleChangeFormImage('img_selfie')
                      }}
                    >
                      Capture
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {/* RENT DATE */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Tanggal Mulai Sewa
              </div>
              <Input name='rent_date' type='date' onChange={handleChangeForm} min='2024-09-21' />
            </div>
            {/* PICKUP OPTION */}
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.5rem' }}>
                Pengambilan Unit
              </div>
              <div style={{ display: 'flex', columnGap: '1rem' }}>
                <button
                  style={{
                    fontFamily: 'Gilroy-Bold',
                    fontSize: '0.875rem',
                    color: pickupOption === 'electrum' ? 'white' : '#0B4571',
                    backgroundColor: pickupOption === 'electrum' ? '#0B4571' : 'white',
                    padding: '0.5rem 0.85rem',
                    borderRadius: '0.375rem',
                    border: '1px solid #0B4571',
                  }}
                  onClick={() => handlePickupOptionChange('electrum')}
                >
                  Ambil di Electrum
                </button>
                <button
                  style={{
                    fontFamily: 'Gilroy-Bold',
                    fontSize: '0.875rem',
                    color: pickupOption === 'home' ? 'white' : '#0B4571',
                    backgroundColor: pickupOption === 'home' ? '#0B4571' : 'white',
                    padding: '0.5rem 0.85rem',
                    borderRadius: '0.375rem',
                    border: '1px solid #0B4571',
                  }}
                  onClick={() => handlePickupOptionChange('home')}
                >
                  Kirim ke Rumah
                </button>
              </div>
            </div>
            {/* ADDRESS */}
            <div style={{ display: pickupOption === 'home' ? 'block' : 'none', marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Alamat
              </div>
              <TextArea rows={4} name='address' onChange={handleChangeForm} />
            </div>
            {/* SUBMIT BUTTON */}
            <div>
              <button
                style={{
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '0.875rem',
                  color: isLoading ? '#8F8F8F' : 'white',
                  backgroundColor: isLoading ? '#E6E6E6' : '#0B4571',
                  border: isLoading ? '2px solid #8F8F8F' : '2px solid #0B4571',
                  padding: '0.5rem 0.85rem',
                  borderRadius: '0.375rem',
                  marginBottom: isLoading ? '0.5rem' : 'initial',
                }}
                onClick={handleSubmitForm}
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
              {isLoading && (
                <Progress percent={uploadProgress} status={isLoading ? 'active' : ''} />
              )}
            </div>
          </div>
        </div>

        {/* FIXED SECTION */}
        <div style={{
          position: 'fixed',
          bottom: '0',
          backgroundColor: '#fff',
          borderRadius: '1.25rem 1.25rem 0 0',
          width: isMobile ? '100%' : '33%',
        }}>
          <div style={{ padding: '1em', display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <a
              href='https://wa.me/6285190013535?text=Halo%21%20Saya%20ingin%20info%20lebih%20lanjut%20mengenai%20Electrum%20H3.'
              target='_blank'
              rel='noreferrer'
              style={{ height: '42.5px' }}
            >
              <img effect='blur' alt='chat-with-us' src={ChatWithUs} width={42.5} style={{ cursor: 'pointer' }} loading='eager' />
            </a>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                color: '#225E8B',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: '2px solid #225E8B',
                padding: '0.375rem 1rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => window.open(
                'https://wa.me/6285190013535?text=Halo%21%20Saya%20mau%20buat%20jadwal%20booking%20test%20ride%20Electrum%20H3.',
                '_blank',
                'rel=noopener noreferrer'
              )}
            >
              Daftar Test-ride
            </button>
            <button
              style={{
                cursor: 'pointer',
                backgroundColor: '#225E8B',
                borderRadius: '0.5rem',
                color: 'white',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.9rem',
                border: 'none',
                padding: '0.375rem 1rem',
                width: '-webkit-fill-available',
              }}
              onClick={() => setChooseModal(true)}
            >
              Buy Now
            </button>
          </div>
        </div>

        {/* CHOOSE MODAL */}
        <Modal
          closeBtn={true}
          onClose={() => setChooseModal(false)}
          isOpen={chooseModal}
        >
          <div style={{ fontSize: '1.5rem', fontFamily: 'Gilroy-Bold', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.25rem' }}>
            Amankan Promonya Sekarang
          </div>
          <div style={{ fontSize: '0.85rem', fontFamily: 'Gilroy-SemiBold', color: '#848484', textAlign: 'center', letterSpacing: '0.03rem', marginBottom: '1.75rem' }}>
            Beli Electrum H3 melalui platform terpercaya kami.
          </div>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
              marginBottom: '1rem',
            }}
            onClick={() => {
              window.open(
                'https://wa.me/6285190013535?text=Halo%21%20Saya%20mau%20promo%20Electrum%20H3.',
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Whatsapp Official Electrum
          </button>
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              color: '#225E8B',
              fontFamily: 'Gilroy-Bold',
              fontSize: '1rem',
              border: '1.5px solid #225E8B',
              padding: '0.65rem 0.75rem',
              width: '100%',
            }}
            onClick={() => {
              window.open(
                'https://tokopedia.link/electrumindonesia',
                '_blank',
                'rel=noopener noreferrer'
              )
              setChooseModal(false)
            }}
          >
            Tokopedia
          </button>
        </Modal>

      </div>
    </div>
  )
}

export default SepekanForm
