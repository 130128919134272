import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const StarRating = ({ rate }) => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((index) => (
        <Star key={index} filled={index <= rate} />
      ))}
    </div>
  )
}

const Star = ({ filled }) => {
  return (
    <span style={{ color: filled ? '#34DEDA' : 'lightgrey', fontSize: '0.75rem' }}>
      <FontAwesomeIcon icon={faStar} />
    </span>
  )
}

export default StarRating
