import React from "react"

function Electrum() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="176"
      height="38"
      fill="none"
      viewBox="0 0 176 38"
    >
      <path
        fill="#103856"
        d="M4.536 23.765c.605 2.218 2.453 3.159 4.738 3.159 1.68 0 3.024-.706 3.763-1.68l3.494 2.015c-1.579 2.285-4.099 3.596-7.325 3.596C3.562 30.855 0 26.99 0 21.985c0-5.007 3.595-8.871 8.87-8.871 4.973 0 8.501 3.931 8.501 8.87 0 .639-.067 1.21-.168 1.781H4.536zm-.067-3.36h8.568c-.538-2.42-2.352-3.393-4.167-3.393-2.318 0-3.897 1.243-4.401 3.393zM20.397 30.384V8.292l4.334-2.436v24.528h-4.334zM32.361 23.765c.605 2.218 2.453 3.159 4.738 3.159 1.68 0 3.024-.706 3.763-1.68l3.494 2.015c-1.58 2.285-4.099 3.596-7.325 3.596-5.644 0-9.206-3.864-9.206-8.87 0-5.007 3.595-8.871 8.87-8.871 4.973 0 8.501 3.931 8.501 8.87 0 .639-.067 1.21-.168 1.781H32.361zm-.067-3.36h8.568c-.538-2.42-2.352-3.393-4.167-3.393-2.318 0-3.897 1.243-4.401 3.393zM56.087 30.855c-5.107 0-8.87-3.864-8.87-8.87 0-5.007 3.763-8.871 8.87-8.871 3.293 0 6.15 1.747 7.527 4.334l-3.73 2.184c-.672-1.377-2.117-2.25-3.83-2.25-2.587 0-4.503 1.914-4.503 4.602 0 2.655 1.916 4.57 4.503 4.57 1.747 0 3.192-.84 3.864-2.218l3.763 2.15c-1.445 2.622-4.3 4.369-7.593 4.369zM75.583 17.75h-3.797v6.99c0 1.814 1.31 1.848 3.797 1.713v3.931c-6.082.672-8.131-1.108-8.131-5.645v-6.988h-2.923v-4.167h2.923v-3.393l4.334-1.31v4.703h3.797v4.167zM83.236 16.474c.874-2.285 2.99-3.226 5.107-3.226v4.839c-2.251-.27-5.107.739-5.107 4.267v8.03h-4.334v-16.8h4.334v2.89zM101.966 13.584h4.335v16.8h-4.335v-1.881c-1.008 1.512-2.755 2.352-5.006 2.352-3.562 0-6.35-2.487-6.35-6.956V13.585h4.334v9.778c0 2.285 1.377 3.46 3.326 3.46 2.117 0 3.696-1.242 3.696-4.166v-9.072zM128.851 13.114c3.831 0 6.351 2.721 6.351 6.921v10.35h-4.335V20.337c0-1.983-1.008-3.192-2.822-3.192-1.915 0-3.125 1.277-3.125 3.797v9.441h-4.334V20.338c0-1.983-1.008-3.192-2.823-3.192-1.848 0-3.158 1.277-3.158 3.797v9.441h-4.335v-16.8h4.335v1.781c.941-1.411 2.52-2.251 4.67-2.251 2.083 0 3.629.873 4.57 2.42 1.041-1.513 2.721-2.42 5.006-2.42z"
      ></path>
      <path
        fill="#40C0C0"
        d="M143.624 9.353a1.178 1.178 0 00.004 2.043l4.479 2.563c.424.243.685.693.685 1.18l-.001 11.954c0 .422.226.811.591 1.021l6.397 3.68a1.179 1.179 0 001.767-1.021l.001-13.085c0-.084.01-.167.016-.25a.974.974 0 00.004-.094l.022-7.37a1.178 1.178 0 00-.598-1.028l-5.809-3.292a1.18 1.18 0 00-1.173.005l-6.385 3.694zM174.314.12a.885.885 0 011.328.766l.006 7.774a.886.886 0 01-.443.767l-7.757 4.48a.885.885 0 01-.887-.002l-6.716-3.9a.884.884 0 01.002-1.532L174.314.12zM174.314 12.068a.885.885 0 011.328.766l.006 7.774a.886.886 0 01-.443.767l-7.757 4.48a.885.885 0 01-.887-.002l-6.716-3.9a.884.884 0 01.002-1.532l14.467-8.353zM174.314 24.016a.885.885 0 011.328.766l.006 7.774a.886.886 0 01-.443.768l-7.757 4.478a.885.885 0 01-.887 0l-6.716-3.901a.884.884 0 01.002-1.532l14.467-8.353z"
      ></path>
    </svg>
  )
}

export default Electrum