import { useEffect, useState } from 'react'
import axios from 'axios'
import { useMutation } from 'react-query'
import io from 'socket.io-client'
import { ELECTRUM_API_URL } from '../consts'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const socket = io(ELECTRUM_API_URL, {
  withCredentials: true,
})

socket.on('connect', () => {
  console.log('Connected to WebSocket server')
})

socket.on('upload-progress', (data) => {
  console.log(`Upload progress: ${data.bytesUploaded} bytes of ${data.totalBytes} for ${data.fileName}`)
})

socket.on('disconnect', () => {
  console.log('Disconnected from WebSocket server')
})

const postRegisterUserVoters = async (payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/user-voters/register-creator`, payload, {
    headers: {
      'x-socket-id': socket.id,
    },
  })
  return result.data
}

const useRegisterUserVoters = () => {
  const navigate = useNavigate()
  const [uploadProgress, setUploadProgress] = useState(0)

  useEffect(() => {
    socket.on('upload-progress', (data) => {
      console.log('data_socket', data)
      // setUploadProgress((data.bytesUploaded / data.totalBytes) * 100)
      setUploadProgress(data.overallProgress)
    })

    return () => {
      socket.off('upload-progress')
    }
  }, [])

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postRegisterUserVoters(payload),
    onSuccess: (response) => {
      if (response.code === 201) {
        toast.success('Submission Submitted Successfully!')
        navigate('/merdeka-hias/gallery')
      } else if (response.code === 400) {
        toast.warn('Email and/or phone number already exists')
        navigate('/merdeka-hias/register')
      } else if (response.code === 500) {
        toast.warn('An error occurred while registering user')
        navigate('/merdeka-hias/register')
      }
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  return { mutate, isLoading, uploadProgress }
}

export default useRegisterUserVoters

