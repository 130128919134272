import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from 'react-query'
import { postRegisterVoters, postValidateOTPVoter } from 'apis/api'
import { toast } from 'react-toastify'

import { BackArrowIcon, ElectrumBlue, KeyIcon, MailIcon, PersonIcon, PhoneIcon } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
} from 'assets/images'

const MerdekaVote = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
    ]
    cacheImages(imgs)
  }, [])

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const [showTnC, setShowTnC] = useState(false)
  const [acceptTNC, setAcceptTNC] = useState(false)

  const { id } = useParams()

  const location = useLocation()
  const { designData } = location.state

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    otp: '',
  })

  const handleChange = (e) => {
    const { name, value, maxLength } = e.target
    if (name === 'otp') {
      if (/^\d{0,6}$/.test(value) && value.length <= 6) {
        const newValue = value.slice(0, maxLength)
        setFormData((prevData) => ({
          ...prevData,
          [name]: newValue,
        }))
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postRegisterVoters(payload),
    onSuccess: (response) => {
      if (response.code === 201) {
        localStorage.setItem('user_data', JSON.stringify(response.user_data))
        toast.success('Vote Submitted Successfully!')
        navigate('/merdeka-hias/gallery')
      } else if (response.code === 400) {
        toast.warn('Maksimal kuota voting 3 desain kamu sudah habis, nih.')
      } else if (response.code === 409) {
        toast.error('Kamu sudah vote untuk design ini')
      } else if (response.code === 401) {
        toast.warn('Invalid OTP')
      }
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  const { mutate: mutateValidate, isLoading: isLoadingValidate } = useMutation({
    mutationFn: (payload) => postValidateOTPVoter(payload),
    onSuccess: (response) => {
      if (response.code === 200) {
        toast.success('Email OTP has been sent!')
      } else if (response.code === 550) {
        toast.error('Failed to send OTP to email')
      }
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  const isNotFilled = formData.fullName === ''
  || formData.email === ''
  || formData.phoneNumber === ''

  const LSOTP = localStorage.getItem('otp')

  const handleSubmitVote = () => {
    if (isNotFilled) {
      toast.warn('Semua kolom harus terisi!')
    } else {
      const payload = {
        authorId: id,
        fullName: formData.fullName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        otp: formData.otp,
      }
      mutate(payload)
    }
  }

  const handleValidate = () => {
    if (formData.email === '') {
      toast.warn('Email cannot be empty!')
    } else {
      mutateValidate({
        email: formData.email,
        fullName: formData.fullName,
        phoneNumber: formData.phoneNumber,
      })
    }
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#ffffff',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: '100',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* QUICK PREVIEW */}
        <div style={{ padding: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.75rem' }}>
            Karya Pilihanmu
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem', marginBottom: '0.75rem' }}>
            {designData.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#0B4571', fontStyle: 'italic', marginBottom: '1.5rem' }}>
            Karya dari <b>{designData.fullName}</b>
          </div>
          <img
            alt={`art-${designData._id}`}
            src={designData.image_t1}
            style={{ width: '100%', borderRadius: '0.75rem' }}
            loading='eager'
          />
        </div>

        {/* FORM */}
        <div style={{ padding: '0 2rem', marginBottom: '2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '0.75rem' }}>
            Data Diri
          </div>
          {/* FULL NAME */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Nama Lengkap <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={PersonIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='text'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='fullName'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* PHONE NUMBER */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Nomor Telefon <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={PhoneIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='number'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='phoneNumber'
                onChange={handleChange}
              />
            </div>
          </div>
          {/* EMAIL ADDRESS */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem',fontFamily: 'Gilroy-Bold' }}>
              Alamat Email <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex' }}>
              <img alt='lock' src={MailIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              <input
                type='email'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='email'
                disabled={formData.fullName === "" || formData.phoneNumber === ""}
                onChange={handleChange}
              />
            </div>
            <div
              style={{
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.875rem',
                color: isLoadingValidate || formData.email === "" ? '#A6A6A6' : '#0B4571',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '0.25rem',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={formData.email !== "" ? handleValidate : null}
            >
              { isLoadingValidate ? 'Sending...' : 'Kirim Email OTP' }
            </div>
          </div>
          {/* OTP */}
          <div style={{ marginBottom: '0.75rem' }}>
            <div style={{ fontSize: '0.85rem', fontWeight: '500', color: '#103856', marginBottom: '0.25rem', fontFamily: 'Gilroy-Bold' }}>
              Masukkan Kode OTP <span style={{ color: 'red' }}>*</span>
            </div>
            <div style={{ display: 'flex', marginBottom: '0.25rem' }}>
              <img alt='lock' src={KeyIcon} height={36.5} width={36.5} style={{ padding: '5px 0' }} />
              {/* <PinInput
                length={6}
                initialValue=""
                secret
                onChange={(value, index) => {
                  setFormData({
                    ...formData,
                    otp: value,
                  })
                }}
                name="otp"
                type="numeric"
                inputMode="numeric"
                onComplete={(value, index) => {}}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                style={pinInputStyles.style}
                inputStyle={pinInputStyles.inputStyle}
                inputFocusStyle={pinInputStyles.inputFocusStyle}
              /> */}
              <input
                type='tel'
                style={{
                  border: '1.75px solid #ECECEC',
                  borderRadius: '7.5px',
                  margin: '5px 0px 5px 7.5px',
                  height: '33.5px',
                  width: '100%',
                  padding: '0 8.5px',
                }}
                name='otp'
                maxLength={6}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* TNC */}
        <div style={{
          backgroundColor: 'white',
          padding: '0rem',
          margin: '0rem 2rem 1rem 2rem',
          borderRadius: '0.625rem',
        }}>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setShowTnC(!showTnC)}
          >
            <div style={{ fontSize: '1.375rem', fontFamily: 'Gilroy-ExtraBold', textAlign: 'center' }}>
              Baca dulu Syarat dan Ketentuan ya
            </div>
            <div style={{ transform: showTnC ? 'rotate(90deg) scale(0.75)' : 'rotate(270deg) scale(0.75)', maxWidth: 'min-content' }}>
              <BackArrowIcon width={20} height={20} />
            </div>
          </div>
          <div style={{ display: showTnC ? 'block' : 'none' }}>
            <div style={{ fontSize: '0.75rem', color: '#103856' }}>
              <ul style={{ fontFamily: 'Gilroy-Semibold', paddingLeft: '1.5rem' }}>
                <li>
                  Pemberi voting (voter) harus melengkapi detail informasi secara valid.
                </li>
                <li>
                  Voting hanya dapat dilakukan pada tanggal 5 Agustus 2024 sampai tanggal 11 Agustus 2024.
                </li>
                <li>
                  Voter hanya dapat memberikan voting maksimal 1 kali per design untuk maksimal 3 design.
                </li>
                <li>
                  Voting design tidak dipungut biaya.
                </li>
                <li>
                  Voter dapat mengikuti program di Instagram @electrum.id untuk mendapatkan voucher total senilai Rp 2.500.000,- untuk 5 orang.
                </li>
                <li>
                  Untuk mendapatkannya, voter wajib follow akun Instagram @electrum.id, dan menuliskan di kolom comment tentang alasan memilih desain tersebut dengan  hashtag #MerdekaHiasElectrumH3, lalu tag 3 teman. Voter juga perlu share post di Instagram story pribadi dengan tag Instagram @electrum.id.
                </li>
                <li>
                  Pengumuman program akan dilakukan pada akun Instagram @electrum.id pada tanggal 17 Agustus 2024.
                </li>
                <li>
                  Keputusan pemilihan voter merupakan hak prerogratif Electrum dan merupakan keputusan yang mutlak dan tidak bisa diganggu gugat.
                </li>
                <li>
                  Data dan informasi pribadi yang dibagikan pada saat pemberian voting dapat digunakan Electrum terkait dengan pengembangan layanan dan kegiatan usaha Electrum, termasuk untuk menghubungi anda pemberian informasi mengenai produk Electrum dan bentuk penawaran atau promosi lainnya. Ketentuan ini tetap berlaku setelah Kompetisi berakhir.
                </li>
              </ul>
            </div>
          </div>
          <div style={{ borderBottom: '0.215rem solid #ECECEC', borderRadius: '100rem', marginTop: '0.35rem' }} />
        </div>

        {/* AGREE TNC */}
        <div style={{ marginBottom: '1.25rem', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
          <input type="checkbox" name="acceptTNC" value={acceptTNC} onClick={() => setAcceptTNC(!acceptTNC)} style={{ transform: 'scale(1.5)', marginRight: '1rem' }} />
          <label style={{ fontSize: '0.875rem', fontWeight: '500', color: '#888888', marginBottom: '0.25rem',fontFamily: 'Gilroy-Medium', textAlign: 'center' }}>
            Saya Telah Membaca dan Menyetujui <br/> <b>Syarat dan Ketentuan</b>
          </label>
        </div>

        {/* BUTTONS */}
        <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
          <button
            style={{
              cursor: acceptTNC && !isLoading ? 'pointer' : 'not-allowed',
              backgroundColor: acceptTNC && !isLoading ? '#0B4571' : '#EBEBEB',
              color: acceptTNC && !isLoading ? 'white' : '#A6A6A6',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: 'none',
              width: '100%',
              marginBottom: '0.5rem',
            }}
            onClick={() => acceptTNC && handleSubmitVote()}
          >
            {isLoading ? 'Loading...' : 'Submit Vote'}
          </button>
          <button
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              backgroundColor: 'white',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: 'none',
              width: '100%',
            }}
            onClick={() => navigate('/merdeka-hias/gallery')}
          >
            Kembali
          </button>
        </div>

      </div>
    </div>
  )
}

export default MerdekaVote
