import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes, faFilter, faHeart } from '@fortawesome/free-solid-svg-icons'
import { Input, Spin, Pagination } from 'antd'

import { ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
  KVMerdekaSquare,
} from 'assets/images'
import { useGetAllDesigns } from 'apis/query'
import OptimizedCloudinaryImage from 'helpers/cloudinary-img-optimizer'
import { convertImageSrcToBlob } from 'helpers/img-to-blob'
import ImageWithSkeleton from 'components/skeleton'

const { Search } = Input

const MerdekaGalleryVoteCount = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
      KVMerdekaSquare,
    ]
    cacheImages(imgs)
  }, [])

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const titleCase = (str) => {
    // TITLE CASE
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    const casedName = splitStr.join(' ')
    // SHORTEN NAME
    const nameParts = casedName.split(' ')
    const firstName = nameParts[0]
    const shortenedParts = nameParts.slice(1).map(name => name.charAt(0) + '.')
    return [firstName, ...shortenedParts].join(' ')
 }

  const [filter, setFilter] = useState('latest')
  const [searchInput, setSearchInput] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearch = (value) => {
    setSearchTerm(value)
    setSearchInput(value)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setSearchInput(value)
    if (value === '') {
      setSearchTerm('')
    }
  }

  const [page, setPage] = useState(1)
  const limit = 10

  const { data, isLoading, isError } = useGetAllDesigns(filter, searchTerm, page, limit)

  const renderGallery = () => {
    return data.data && data.data.map((item) => {
      return (
        <div
          key={item.id}
          style={{ cursor: 'pointer' }}
          onClick={() => window.open(`/merdeka-hias/vote/${item._id}`)}
        >
          <OptimizedCloudinaryImage
            src={item.image_t1}
            alt='thumbnail'
            width='300'
            height='300'
          />
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginTop: '0.35rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem' }}>
                {item.title}
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.785rem' }}>
                By: {titleCase(item.fullName)}
              </div>
            </div>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem', color: '#F04C3E', display: 'flex', alignItems: 'end', columnGap: '0.5rem' }}>
              {item.designVotes}
              <FontAwesomeIcon icon={faHeart} fontSize='1.25rem' color='#F04C3E' />
            </div>
          </div>
        </div>
      )
    })
  }

  const voterDataRaw = localStorage.getItem('user_data')
  const voterDataParsed = JSON.parse(voterDataRaw)

  const [imageBlobs, setImageBlobs] = useState({})
  const imageSources = [KVMerdekaSquare, Lease]

  useEffect(() => {
    const handleConvertImages = async () => {
      const blobPromises = imageSources.map((src) => convertImageSrcToBlob(src))
      try {
        const blobsArray = await Promise.all(blobPromises)
        const blobsObject = imageSources.reduce((acc, src, index) => {
          acc[src] = URL.createObjectURL(blobsArray[index])
          return acc
        }, {})
        setImageBlobs(blobsObject)
      } catch (error) {
        console.error('Error converting images to blobs:', error)
      }
    }
    handleConvertImages()
  }, [])

  const galleryElement = () => {
    return (
      <>
        {/* MAIN KV */}
        <img
          alt='main_kv'
          src={imageBlobs[imageSources[0]]}
          style={{ width: '100%' }}
          loading='eager'
        />
        {/* SEARCH / FILTER */}
        <div style={{ padding: '1.5rem 2rem 2rem 2rem' }}>
          <Search
            allowClear
            placeholder='Cari Artist'
            name='name'
            onChange={handleInputChange}
            onSearch={handleSearch}
            style={{
              marginBottom: '1rem',
              borderRadius: '6px',
              boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.1)',
              marginBottom: '1.5rem',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
            <div style={{ marginRight: '1.25rem' }}>
              <FontAwesomeIcon icon={faFilter} fontSize='1rem' />{' '}
              <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem' }}>
                Urutkan :
              </span>
            </div>
            <div style={{ display: 'flex', columnGap: '0.65rem' }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.75rem',
                  color: filter === 'latest' ? '#FFFFFF' : '#0B4571',
                  backgroundColor: filter === 'latest' ? '#0B4571' : '#FFFFFF',
                  border: '0.1125rem solid #0B4571',
                  borderRadius: '20rem',
                  padding: '0.25rem 0.5rem',
                  textAlign: 'center',
                }}
                onClick={() => {
                  setFilter('latest')
                  setPage(1)
                }}
              >
                Paling Baru
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.75rem',
                  color: filter === 'earliest' ? '#FFFFFF' : '#0B4571',
                  backgroundColor: filter === 'earliest' ? '#0B4571' : '#FFFFFF',
                  border: '0.1125rem solid #0B4571',
                  borderRadius: '20rem',
                  padding: '0.25rem 0.5rem',
                  textAlign: 'center',
                }}
                onClick={() => {
                  setFilter('earliest')
                  setPage(1)
                }}
              >
                Paling Awal
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '0.75rem',
                  color: filter === 'mostLiked' ? '#FFFFFF' : '#0B4571',
                  backgroundColor: filter === 'mostLiked' ? '#0B4571' : '#FFFFFF',
                  border: '0.1125rem solid #0B4571',
                  borderRadius: '20rem',
                  padding: '0.25rem 0.5rem',
                  textAlign: 'center',
                }}
                onClick={() => {
                  setFilter('mostLiked')
                  setPage(1)
                }}
              >
                Paling Disukai
              </div>
            </div>
          </div>
        </div>
        {/* GALLERY */}
        <div style={{ padding: '0 2rem 2rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', marginBottom: '1rem' }}>
            Galeri Merdeka Hias Electrum H3
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem', marginBottom: '0.75rem' }}>
            Klik gambar untuk vote karya favoritmu dan dapatkan total Rp 2,5 juta untuk voter desain!
          </div>
          <div style={{
            fontFamily: 'Gilroy-SemiBold',
            fontSize: '0.75rem',
            color: '#0B4571',
            marginBottom: '1.5rem',
            border: '1.5px solid #0B4571',
            borderRadius: '50rem',
            width: 'fit-content',
            padding: '0.25rem 0.5rem',
          }}>
            Kuota vote tersisa : { voterDataRaw ? 3 - voterDataParsed.votedDesigns.length : 3 }
          </div>
          <div style={{ display: 'grid', gap: '1.5rem', gridTemplateColumns: 'repeat(2, 1fr)' }}>
            {renderGallery()}
          </div>
        </div>
        {/* PAGINATION */}
        {data && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: '0 1rem 1.5rem 1rem' }}>
            <Pagination
              current={page}
              total={data.total_data}
              onChange={setPage}
              showSizeChanger={false}
            />
          </div>
        )}
        {/* LOGOUT BTN */}
        {localStorage.getItem('user_data') !== null && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#f04c3e',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.875rem',
                padding: '0.675rem 3rem',
                borderRadius: '0.5rem',
                marginBottom: '2rem',
              }}
              onClick={() => {
                localStorage.removeItem('user_data')
                navigate('/merdeka-hias')
              }}
            >
              Keluar
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#ffffff',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: '100',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* GALLERY */}
        {
          isLoading
          ? (
            <div style={{ height: '100%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,0)' }}>
              <Spin size='large' />
            </div>
          ) : galleryElement()
        }

      </div>
    </div>
  )
}

export default MerdekaGalleryVoteCount
