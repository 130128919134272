import React from 'react'

const extractPath = (url) => {
  const parts = url.split('/')
  return parts.slice(6).join('/')
}

const OptimizedCloudinaryImage = ({ src, alt, width, height, style }) => {
  const cloudinaryBaseUrl = 'https://res.cloudinary.com/aldrichn/image/upload/'
  const optimizedSrc = `${cloudinaryBaseUrl}w_${width},h_${height},c_fill,q_auto,f_auto/${extractPath(src)}`

  return (
    <img
      src={optimizedSrc}
      alt={alt}
      width={width}
      height={height}
      loading="lazy"
      style={{
        width: '100%',
        height: 'auto',
        aspectRatio: 1,
        borderRadius: '0.75rem',
        objectFit: 'cover',
        clipPath: 'inset(0 0 0 0)',
        ...style,
      }}
    />
  )
}

export default OptimizedCloudinaryImage
