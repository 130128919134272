import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'

const PasswordProtected = ({ children, correctPassword }) => {
  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [enteredPassword, setEnteredPassword] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [error, setError] = useState('')

  const handlePasswordChange = (e) => {
    setEnteredPassword(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (enteredPassword === correctPassword) {
      setIsAuthenticated(true)
    } else {
      setError('Incorrect password. Please try again.')
    }
  }

  if (isAuthenticated) {
    return children
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div style={{
        height: '100vh',
        overflow: 'scroll',
        backgroundColor: 'white',
      }}>
        <center style={{ padding: '2.5rem 0' }}>
          <div style={{
            fontFamily: 'Gilroy-Bold',
            fontSize: '1.375rem',
            margin: '1rem 0rem',
          }}>
            Enter Password to Access
          </div>
          <form onSubmit={handleSubmit}>
            <Input.Password
              type="password"
              value={enteredPassword}
              onChange={handlePasswordChange}
              style={{ width: '13.5rem', marginRight: '0.5rem' }}
            />
            <Button
              type="primary"
              onSubmit={handleSubmit}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </center>
      </div>
    </div>
  )
}

export default PasswordProtected
