import axios from "axios"
import { ELECTRUM_API_URL } from "../consts"

export const postRegisterVoters = async(payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/votes/design-new`, payload)
  return result.data
}

export const postValidateOTPVoter = async(payload) => {
  const result = await axios.post(`${ELECTRUM_API_URL}/votes/validate`, payload)
  return result.data
}
