import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { ArrowRight, ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
  KVMerdeka,
} from 'assets/images'
import { useGetAllDesigns } from 'apis/query'
import OptimizedCloudinaryImage from 'helpers/cloudinary-img-optimizer'
import { Spin } from 'antd'
import ImageBlob from 'components/imageBlob'

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const MerdekaHias = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
      KVMerdeka,
    ]
    cacheImages(imgs)
  }, [])

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const { data, isLoading, isError } = useGetAllDesigns('latest', '', 1, 5)

  const renderSpotlight = () => {
    return data && data.data.map((item, index) => (
      <div
        key={index}
        style={{
          borderRadius: '0.5rem',
          backgroundColor: 'white',
        }}
      >
        <OptimizedCloudinaryImage
          src={item.image_t1}
          alt='thumbnail'
          width='300'
          height='300'
          style={{ width: '10rem' }}
        />
      </div>
    ))
  }

  const [showGallery, setShowGallery] = useState(true)
  const [showRegister, setShowRegister] = useState(true)

  const voteOpenDate = '2024-08-12' // Format: YYYY-MM-DD

  const calculateTimeLeft = () => {
    const difference = +new Date(voteOpenDate) - +new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (!isObjectEmpty(timeLeft)) {
      setShowGallery(false)
      setShowRegister(true)
    } else {
      setShowGallery(true)
      setShowRegister(false)
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
      return () => clearTimeout(timer)
    }
  })

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#ffffff',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: '100',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* MAIN KV */}
        <div style={{ position: 'relative' }}>
          <ImageBlob
            alt='top-banner'
            src={KVMerdeka}
            style={{ width: '100%' }}
            loading='eager'
          />
          {showRegister && (
            <>
              <div style={{ padding: '0 2rem', marginTop: '1rem', fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#103856' }}>
                Terima kasih kepada 400+ peserta yang sudah mengirimkan karya dalam kompetisi Merdeka Hias Electrum H3. Kamu masih dapat mengirimkan karya hingga 11 Agustus 2024 untuk memenangkan total 2 Motor Listrik Electrum H3. <br />
                <b>Yuk voting karya favoritmu pada gallery di bawah ini.</b>
              </div>
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  fontFamily: 'Gilroy-Bold',
                  color: 'white',
                  fontSize: '0.875rem',
                  backgroundColor: '#0B4571',
                  padding: '0.75rem 1rem',
                  margin: '1rem 2rem 2rem 2rem',
                  border: 'none',
                  borderRadius: '0.625rem',
                }}
                onClick={() => navigate('/merdeka-hias/instruction')}
              >
                Daftar untuk dapat motor listrik H3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <ArrowRight />
              </button>
            </>
          )}
        </div>

        {/* SEE GALLERY */}
        {true && (
          <div style={{ padding: '0.5rem 2rem 2rem 2rem', marginTop: '1rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', marginBottom: '0.75rem' }}>
              Galeri Merdeka Hias
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#103856', marginBottom: '0.75rem' }}>
              Vote karya favorit kamu dan dapatkan total senilai Rp 2.500.000 dari Electrum!
            </div>
            {
              isLoading
              ? (
                <div style={{ margin: '2rem 0', display: 'flex', justifyContent: 'center' }}>
                  <Spin size='large' />
                </div>
              ) : (
                <div style={{
                  paddingTop: '0.75rem',
                  paddingBottom: '1rem',
                  display: 'flex',
                  overflowX: 'scroll',
                  columnGap: '1rem',
                }}>
                  {renderSpotlight()}
                </div>
              )
            }
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                fontFamily: 'Gilroy-Bold',
                color: 'white',
                fontSize: '0.875rem',
                backgroundColor: '#0B4571',
                padding: '0.75rem 1rem',
                border: 'none',
                borderRadius: '0.625rem',
              }}
              onClick={() => navigate('/merdeka-hias/gallery')}
            >
              Lihat Karya <ArrowRight />
            </button>
          </div>
        )}

      </div>
    </div>
  )
}

export default MerdekaHias
