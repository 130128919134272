import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import LanguageProvider from 'helpers/language-provider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'components/slider/styles.css'

import {
  HomeOld,
  HomeNewPromo,
  HomeNewProduct,
  HomeV2,
  MerdekaHias,
  MerdekaInstruction,
  MerdekaRegister,
  MerdekaPreview,
  MerdekaGallery,
  MerdekaGalleryVoteCount,
  MerdekaVote,
  MerdekaVoteRegister,
  SepekanForm,
  CostCalculator,
  PasswordProtected,
  CTAProtectedContent,
} from 'pages'
import { useState } from 'react'

const queryClient = new QueryClient()

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const App = () => {
  const correctPassword = '9SnBFHlhtwiEYvo'

  const MerdekaHiasLayout = () => {
    return (
      <div>
        <Outlet />
      </div>
    )
  }

  const voteOpenDate = '2024-08-12' // Format: YYYY-MM-DD

  const calculateTimeLeft = () => {
    const difference = +new Date(voteOpenDate) - +new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  const routes = () => {
    return (
      <Router>
        <LanguageProvider>
          <Routes>

            {/* PRODUCTION ROUTE */}
            <Route path="/" element={<HomeV2 />} />

            {/* SEPEKAN */}
            <Route path="/sepekan-form" element={<SepekanForm />} />

            {/* MERDEKA HIAS ROUTE */}
            <Route path="/merdeka-hias" element={<MerdekaHias />} />
            <Route path="/merdeka-hias/*" element={<MerdekaHiasLayout />}>
              <Route
                path="instruction"
                element={
                  !isObjectEmpty(timeLeft)
                    ? <MerdekaInstruction />
                    : <Navigate to="/merdeka-hias" replace />
                }
              />
              <Route
                path="register"
                element={
                  !isObjectEmpty(timeLeft)
                    ? <MerdekaRegister />
                    : <Navigate to="/merdeka-hias" replace />
                }
              />
              <Route
                path="preview"
                element={
                  !isObjectEmpty(timeLeft)
                    ? <MerdekaPreview />
                    : <Navigate to="/merdeka-hias" replace />
                }
              />
              <Route path="gallery" element={<MerdekaGallery />} />
              <Route path="vote/:id" element={<MerdekaVote />} />
              <Route
                path="vote-register/:id"
                element={
                  !isObjectEmpty(timeLeft)
                    ? <MerdekaVoteRegister />
                    : <Navigate to="/merdeka-hias" replace />
                }
              />
            </Route>

            {/* REDIRECT LANGUAGE */}
            <Route path="/id" element={<Navigate to="/" replace />} />
            <Route path="/en" element={<Navigate to="/" replace />} />

            {/* PRIVATE ROUTE */}
            {/* <Route
              path="/promo"
              element={
                <PasswordProtected correctPassword={correctPassword}>
                  <HomeNewPromo />
                </PasswordProtected>
              }
            />
            <Route
              path="/product"
              element={
                <PasswordProtected correctPassword={correctPassword}>
                  <HomeNewProduct />
                </PasswordProtected>
              }
            />
            <Route
              path="/cost-calculator"
              element={
                <PasswordProtected correctPassword={correctPassword}>
                  <CostCalculator />
                </PasswordProtected>
              }
            />
            <Route
              path="/home-old"
              element={
                <PasswordProtected correctPassword={correctPassword}>
                  <HomeOld />
                </PasswordProtected>
              }
            />
            <Route
              path="/wa"
              element={
                <PasswordProtected correctPassword={correctPassword}>
                  <CTAProtectedContent />
                </PasswordProtected>
              }
            /> */}
            <Route
              path="/merdeka-gallery-votes"
              element={
                <PasswordProtected correctPassword={correctPassword}>
                  <MerdekaGalleryVoteCount />
                </PasswordProtected>
              }
            />
          </Routes>
        </LanguageProvider>
      </Router>
    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        autoClose={2000}
        position="top-center"
      />
      {routes()}
    </QueryClientProvider>
  )
}

export default App
