import React, { useEffect, useState } from 'react'
import { Modal } from 'common'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from 'react-query'
import { postRegisterVoters } from 'apis/api'
import { toast } from 'react-toastify'
import { Modal as ModalImage, ModalContent as ModalImageContent } from 'components/modal-image'

import { ElectrumBlue } from 'assets/icons'
import {
  BatterySwap,
  ChatWithUs,
  Future,
  H3Dark,
  H3Light,
  Mileage,
  Promo,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  Specification,
  TopBannerJune,
  Efficient1,
  Efficient2,
  DangerFree1,
  DangerFree2,
  Renewable1,
  Renewable2,
  Smart1,
  Smart2,
  Latest1,
  Spacious1,
  Spacious2,
  UniqueLong,
  Calm1,
  Calm2,
  Lease,
  Flexible,
  ServiceCenter,
  KVMerdekaSquare,
} from 'assets/images'
import { Spin, Steps } from 'antd'
import { useGetDesignById } from 'apis/query'
import OptimizedCloudinaryImage from 'helpers/cloudinary-img-optimizer'

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const MerdekaVote = () => {
  const navigate = useNavigate()

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const [showDropdown, setShowDropdown] = useState(false)
  const [chooseModal, setChooseModal] = useState(false)

  const cacheImages = async(srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image()
        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })
    await Promise.all(promises)
  }

  useEffect(() => {
    const imgs = [
      BatterySwap,
      ChatWithUs,
      Future,
      H3Dark,
      H3Light,
      Mileage,
      Promo,
      Reviewer1,
      Reviewer2,
      Reviewer3,
      Riders,
      Specification,
      TopBannerJune,
      Efficient1,
      Efficient2,
      DangerFree1,
      DangerFree2,
      Renewable1,
      Renewable2,
      Smart1,
      Smart2,
      Latest1,
      Spacious1,
      Spacious2,
      UniqueLong,
      Calm1,
      Calm2,
      Lease,
      Flexible,
      ServiceCenter,
      KVMerdekaSquare,
    ]
    cacheImages(imgs)
  }, [])

  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 })
  const scrollDemoRef = React.useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const idleTimeoutRef = React.useRef(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft, scrollHeight, clientHeight } = scrollDemoRef.current
      // Set scroll position to state
      setScrollPosition({ scrollTop, scrollLeft })
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true)
      } else {
        setIsScrolledToBottom(false)
      }
      // Reset the idle timer
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current)
      }
      idleTimeoutRef.current = setTimeout(() => {
        setShowPopup(true)
      }, 1500) // 1.5 seconds
    }
  }

  useEffect(() => {
    const scrollElement = scrollDemoRef.current
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll)
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll)
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current)
        }
      }
    }
  }, [])

  const [animating, setAnimating] = useState(false)

  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }

  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => postRegisterVoters(payload),
    onSuccess: (response) => {
      if (response.code === 201) {
        localStorage.setItem('user_data', JSON.stringify(response.user_data))
        toast.success('Vote Submitted Successfully!')
        navigate('/merdeka-hias/gallery')
      } else if (response.code === 400) {
        toast.warn('Maksimal kuota voting 3 desain kamu sudah habis, nih.')
      } else if (response.code === 409) {
        toast.error('Kamu sudah vote untuk design ini')
      } else if (response.code === 401) {
        toast.warn('Invalid OTP.')
      }
    },
    onError: (err) => {
      const errMessage = err.response.data.message
      toast.error(errMessage)
    },
  })

  const { id } = useParams()

  const { data: designData, isLoading: designLoading, isError } = useGetDesignById(id)

  const handleSubmitVote = () => {
    const userDataLS = localStorage.getItem('user_data')
    if (userDataLS) {
      const userDataParsed = JSON.parse(userDataLS)
      const payload = {
        authorId: id,
        fullName: userDataParsed.fullName,
        email: userDataParsed.email,
        phoneNumber: userDataParsed.phoneNumber,
        otp: userDataParsed.otp,
      }
      mutate(payload)
    } else {
      navigate(`/merdeka-hias/vote-register/${id}`, {
        state: { designData },
      })
    }
  }

  const [showSubmitVoteBtn, setShowSubmitVoteBtn] = useState(true)

  const voteOpenDate = '2024-08-04' // Format: YYYY-MM-DD

  const calculateTimeLeft = () => {
    const difference = +new Date(voteOpenDate) - +new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (!isObjectEmpty(timeLeft)) {
      setShowSubmitVoteBtn(false)
    } else {
      setShowSubmitVoteBtn(true)
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
      return () => clearTimeout(timer)
    }
  })

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time
  }

  const [showSub1, setShowSub1] = useState(false)
  const [showSub2, setShowSub2] = useState(false)
  const [showSub3, setShowSub3] = useState(false)

  const contentElements = () => {
    return (
      <>
        {/* MAIN KV */}
        <img
          alt='main kv'
          src={KVMerdekaSquare}
          style={{ width: '100%' }}
          loading='eager'
        />

        {/* CONTENT PREVIEW */}
        <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.375rem', margin: '0.75rem 0' }}>
            {designData.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#0B4571', marginBottom: '1.125rem' }}>
            Karya dari <b>{designData.fullName}</b>
          </div>
          <pre style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.875rem', whiteSpace: 'pre-wrap', marginBottom: '2rem' }}>
            {designData.profile_description}
          </pre>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
            <div>
              <ModalImage onOpen={() => setShowSub1(true)}>
                <OptimizedCloudinaryImage
                  src={designData.image_t1}
                  alt='submission1'
                  width='300'
                  height='300'
                  style={{ cursor: 'pointer' }}
                />
              </ModalImage>
              {showSub1 && (
                <ModalImageContent isOpen={showSub1} onClose={() => setShowSub1(false)}>
                  <img
                    alt={`submission1`}
                    src={designData.image_t1}
                    loading='eager'
                    style={{ width: '100%', height: 'auto', maxWidth: '100vw', maxHeight: '100vh', objectFit: 'contain' }}
                  />
                </ModalImageContent>
              )}
            </div>
            <div>
            <ModalImage onOpen={() => setShowSub2(true)}>
              <OptimizedCloudinaryImage
                src={designData.image_t2}
                alt='submission2'
                width='300'
                height='300'
                style={{ cursor: 'pointer' }}
              />
              </ModalImage>
              {showSub2 && (
                <ModalImageContent isOpen={showSub2} onClose={() => setShowSub2(false)}>
                  <img
                    alt={`submission2`}
                    src={designData.image_t2}
                    loading='eager'
                    style={{ width: '100%', height: 'auto', maxWidth: '100vw', maxHeight: '100vh', objectFit: 'contain' }}
                  />
                </ModalImageContent>
              )}
            </div>
            <div>
            <ModalImage onOpen={() => setShowSub3(true)}>
              <OptimizedCloudinaryImage
                src={designData.image_t3}
                alt='submission3'
                width='300'
                height='300'
                style={{ cursor: 'pointer' }}
              />
              </ModalImage>
              {showSub3 && (
                <ModalImageContent isOpen={showSub3} onClose={() => setShowSub3(false)}>
                  <img
                    alt={`submission3`}
                    src={designData.image_t3}
                    loading='eager'
                    style={{ width: '100%', height: 'auto', maxWidth: '100vw', maxHeight: '100vh', objectFit: 'contain' }}
                  />
                </ModalImageContent>
              )}
            </div>
          </div>
        </div>

        {/* BUTTONS */}
        <div style={{ padding: '1rem 2rem 2rem 2rem' }}>
          {showSubmitVoteBtn && false && (
            <button
              style={{
                cursor: !isLoading ? 'pointer' : 'not-allowed',
                backgroundColor: !isLoading ? '#0B4571' : '#EBEBEB',
                color: !isLoading ? 'white' : '#A6A6A6',
                fontFamily: 'Gilroy-Bold',
                fontSize: '0.875rem',
                padding: '0.675rem 0',
                borderRadius: '0.5rem',
                border: 'none',
                width: '100%',
                marginBottom: '0.5rem',
              }}
              onClick={() => handleSubmitVote()}
            >
              {isLoading ? 'Processing...' : 'Submit Vote'}
            </button>
          )}
          <button
            style={{
              cursor: 'pointer',
              color: '#0B4571',
              backgroundColor: 'white',
              fontFamily: 'Gilroy-Bold',
              fontSize: '0.875rem',
              padding: '0.675rem 0',
              borderRadius: '0.5rem',
              border: '1.5px solid #0B4571',
              width: '100%',
            }}
            onClick={() => navigate('/merdeka-hias/gallery')}
          >
            Kembali
          </button>
        </div>
      </>
    )
  }

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflow: 'scroll',
          backgroundColor: '#ffffff',
        }}
        ref={scrollDemoRef}
        onScroll={handleScroll}
      >

        {/* TOP TITLE */}
        <div style={{
          position: 'sticky',
          top: '0',
          zIndex: '100',
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.75rem 2rem',
            backgroundColor: '#fff',
          }}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={showDropdown ? faTimes : faBars}
                color='#103856'
                fontSize='1.5rem'
                className={`icon-transition ${showDropdown ? 'icon-transition-enter' : 'icon-transition-exit'}`}
                onAnimationEnd={handleAnimationEnd}
              />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <ElectrumBlue />
            </div>
            <div style={{ visibility: 'hidden' }}>
              <FontAwesomeIcon icon={faBars} color='#103856' fontSize='1.5rem' />
            </div>
          </div>
          <div style={{ display: showDropdown ? 'block' : 'none' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
              backgroundColor: 'white',
              textAlign: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '1rem',
              borderRadius: '0 0 1rem 1rem',
            }}>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/')}
              >
                Promo
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '1.125rem',
                  color: '#103856',
                }}
                onClick={() => navigate('/product')}
              >
                Product
              </div>
            </div>
          </div>
        </div>

        {/* CONTENT */}
        {
          designLoading
          ? (
            <div style={{ height: '100%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,0)' }}>
              <Spin size='large' />
            </div>
          ) : contentElements()
        }

      </div>
    </div>
  )
}

export default MerdekaVote
